<template>
  <div>
    <div class="d-flex justify-content-end fakeTop">
      <b-button variant="primary" @click="openModal()"
        >Adicionar regra de convênio</b-button
      >
    </div>

    <b-row>
      <b-col class="justify-content-center align-self-center">
        <h3>Convênio médico</h3>
      </b-col>

      <b-col md="4" offset="5" align-self="center">
        <multi-select
          placeholder="Profissional"
          v-model="professionalFilterTable"
          :options="doctorsOptions"
          :searchable="true"
          @remove="handleRemotion($event, 'professionalFilterTable')"
          @select="handleInput($event, 'professionalFilterTable')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table-simple class="table">
          <thead>
            <tr>
              <td class="td-head">Médico</td>
              <td class="td-head">Convênio atendido</td>
              <td class="td-head">Tipo</td>
              <td class="td-head">Dias da semana</td>
              <td class="td-head">Limite de paciente</td>
              <td class="td-head">Ação</td>
              <td class="td-head"></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in data" :key="entry.id">
              <td>{{ entry.doctor }}</td>
              <td>{{ entry.healthPlan }}</td>
              <td>{{ entry.type }}</td>
              <td>{{ entry.days }}</td>
              <td>{{ entry.limit }}</td>
              <td>{{ entry.action }}</td>

              <td>
                <div class="actions">
                  <Icon tooltip="Editar restrição">
                    <Pencil
                      class="pointer"
                      @click="
                        editContraint(
                          entry.id,
                          entry.rawDoctor,
                          entry.rawHealthPlan,
                          entry.rawContrainstConfig,
                          entry.rawConstraintAction
                        )
                      "
                    />
                  </Icon>

                  <Icon tooltip="Remover restrição">
                    <Delete class="pointer" @click="deleteConstraint(entry.id)" />
                  </Icon>
                </div>
              </td>
            </tr>
          </tbody>
        </b-table-simple>
      </b-col>
    </b-row>

    <div class="centered" v-if="data.length === 0">
      <img
        src="@/assets/images/schedule-constraints-no-data.png"
        alt="Nenhuma restrição cadastrada"
        class="centered"
      />

      <p>Não há nenhuma regra cadastrada.</p>

      <p>Clique no "+” para adicionar uma nova regra.</p>
    </div>

    <b-modal v-model="modalShow" size="lg" centered content-class="modalRule">
      <template #modal-header="{ close }">
        <p>Nova regra de convênio</p>

        <Icon class="closeIcon">
          <Close class="pointer" @click="close()" />
        </Icon>
      </template>

      <template #default>
        <b-row>
          <b-col>
            <p>Médico</p>

            <multi-select
              v-model="tempDoctors"
              :options="doctorsOptions"
              :searchable="true"
              @remove="handleRemotion($event, 'professionalAddRule')"
              @select="handleInput($event, 'professionalAddRule')"
            />

            <div v-if="validationError.doctors" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-row>
              <b-col md="6">
                <p>Convênio</p>

                <multi-select
                  v-model="tempHealthPlan"
                  :options="healthPlansOptions"
                  :searchable="true"
                />

                <div v-if="validationError.healthPlans" class="custom-invalid-feedback">
                  Campo obrigatório
                </div>
              </b-col>

              <b-col md="6">
                <p>Tipo</p>

                <multi-select
                  v-model="tempType"
                  :options="typeOptions"
                  :searchable="true"
                />

                <div v-if="validationError.types" class="custom-invalid-feedback">
                  Campo obrigatório
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <p>Selecione os dias de atendimento</p>

            <multi-select v-model="tempDays" :options="daysOptions" :searchable="true" />

            <div v-if="validationError.days" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-col>

          <b-col md="3" class="pseudoMd3">
            <p>Limite de pacientes</p>
            <b-form-input type="number" v-model="amount"></b-form-input>

            <div v-if="validationError.amount" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-col>
          <div class="alignBottom">
            <br />
            <p>por</p>
          </div>
          <b-col md="3" class="pseudoMd3">
            <p>&nbsp;</p>
            <multi-select
              v-model="tempLimit"
              :options="limitOptions"
              :searchable="false"
              :multiple="false"
            />

            <div v-if="validationError.limit" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <span>
              Ação a ser tomada
              <Icon v-b-popover.hover="tooltip" class="iconTooltip">
                <Info class="pointer" />
              </Icon>
            </span>
          </b-col>

          <b-col md="12">
            <b-form-group>
              <b-form-radio-group
                v-model="dataToSave.constraint_action"
                :options="optionsActions"
                name="radio-options"
                class="radioText"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #modal-footer>
        <b-button variant="primary" @click="saveRestriction()"
          >+ Adicionar restrição</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import Icon from "@/components/General/Icon";
import Delete from "@/assets/icons/delete.svg";
import Pencil from "@/assets/icons/pencil.svg";
import Close from "@/assets/icons/close.svg";
import Info from "@/assets/icons/info-icon.svg";

export default {
  components: {
    MultiSelect: () => import("@/modules/schedule/components/MultiSelect"),
    Icon,
    Delete,
    Pencil,
    Close,
    Info,
  },
  name: "RestrictionsHealthPlan",
  created() {
    // this.getData();
    this.getDoctors();
    this.getHealthPlans();
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem("clinic")),

      professionalFilterTable: null,
      professionalOnRuleAdd: null,
      doctorsOptions: [],
      modalShow: false,
      optionsActions: [
        { value: "warning", text: "Apenas avisar" },
        { value: "block", text: "Bloqueio agendamento" },
      ],

      tempDoctors: [],

      dataToSave: {
        id: null,
        clinic_id: null,
        constraint_action: "warning",
        constraint_config: {
          types: [],
          days: [],
          amount: null,
          limit: null,
        },
        healthPlans: [],
        doctors: [],
      },

      data: [],

      healthPlansOptions: [],

      daysOptions: [
        { label: "Domingo", value: "domingo" },
        { label: "Segunda", value: "segunda-feira" },
        { label: "Terça", value: "terça-feira" },
        { label: "Quarta", value: "quarta-feira" },
        { label: "Quinta", value: "quinta-feira" },
        { label: "Sexta", value: "sexta-feira" },
        { label: "Sábado", value: "sábado" },
      ],

      typeOptions: [
        { label: "Centro cirúrgico", value: "SURGICAL" },
        { label: "Procedimento", value: "PROCEDURE" },
        { label: "Consulta", value: "APPOINTMENT" },
        { label: "Telemedicina", value: "TELEMEDICINE" },
        { label: "Exame", value: "EXAM" },
        { label: "Retorno", value: "RETURN" },
      ],

      limitOptions: [
        { label: "dia", value: "day" },
        { label: "semana", value: "week" },
        { label: "mês", value: "month" },
      ],

      tempHealthPlan: [],
      tempType: [],
      tempDays: [],
      tempLimit: [],
      amount: null,

      validationError: {
        doctors: false,
        healthPlans: false,
        types: false,
        days: false,
        amount: false,
        limit: false,
      },
    };
  },
  props: {},
  computed: {
    tooltip() {
      return {
        html: true,
        placement: "bottom",
        trigger: "hover",

        content: () => {
          return "<strong>Diferença nas ações a serem tomadas:</strong><br><br><strong>Apenas avisar:</strong> Essa opção apenas avisa na sua agenda que a idade não se enquandra no perfil de pacientes determinado, mas permite o agendamento.<br><br><strong>Bloquear agendamento:</strong> Essa opção não permite o agendamento de qualquer paciente que não se enquadre no perfil de paciente determinado pela clínica ou pelo médico.";
        },

        customClass: "tooltip-custom",
      };
    },
  },
  methods: {
    async editContraint(id, professional, healthPlan, constraintConfig, action) {
      this.dataToSave.id = id;
      this.tempDoctors = professional;

      this.tempHealthPlan = healthPlan;

      this.tempType = this.typeOptions.filter((option) => {
        return constraintConfig.types.includes(option.value);
      });

      this.tempDays = this.daysOptions.filter((option) => {
        return constraintConfig.days.includes(option.value);
      });

      this.amount = constraintConfig.amount;

      this.tempLimit = this.limitOptions.find(
        (option) => option.value === constraintConfig.limit
      );

      this.dataToSave.constraint_action = action;

      this.modalShow = true;
    },
    async deleteConstraint(id) {
      await this.api.deleteConstraint(id).then((response) => {
        this.getData();
      });
    },
    async getHealthPlans() {
      const options = [];
      let next = true;
      let currentPage = 1;

      const plans = [];

      while (next) {
        await this.api
          .getClinicHealthPlans(this.clinic.id, currentPage)
          .then((response) => {
            for (let i = 0; i < response.data.data.length; i++) {
              if (
                plans.includes(
                  response.data.data[i].health_plan_fantasy_name.toLowerCase().trim()
                )
              ) {
                continue;
              }

              options.push({
                label: response.data.data[i].health_plan_fantasy_name,
                value: response.data.data[i].health_plan.id,
              });

              plans.push(
                response.data.data[i].health_plan_fantasy_name.toLowerCase().trim()
              );
            }

            if (currentPage === response.data.last_page) {
              next = false;
            } else {
              currentPage++;
              next = true;
            }
          });
      }

      this.healthPlansOptions = options;
    },
    async getData(doctors = null) {
      const data = await this.api.indexScheduleConstraintsFiltered(
        this.clinic.id,
        encodeURIComponent(doctors)
      );

      const temp = [];

      data.data.forEach((entry) => {
        const doctor = entry.doctor;
        const constraints = entry.constraint_config;

        if (entry.health_plan_id && typeof entry.health_plan_id === "string") {
          temp.push({
            id: entry.id,

            rawDoctor: [
              {
                label: doctor.name,
                value: doctor.id,
              },
            ],
            rawContrainstConfig: JSON.parse(JSON.stringify(entry.constraint_config)),
            rawConstraintAction: entry.constraint_action,

            rawHealthPlan: [
              {
                label: entry.health_plan?.fantasy_name,
                value: entry.health_plan_id,
              },
            ],

            doctor: doctor.name,
            healthPlan: entry.health_plan?.fantasy_name,
            type: this.handleTypes(constraints.types).join(", "),
            days: constraints.days.join(", "),
            limit: constraints.amount + " por " + this.handleLimitType(constraints.limit),
            action:
              entry.constraint_action === "warning"
                ? "Aviso na agenda"
                : "Bloqueio da agenda",
          });
        }
      });

      this.data = temp;
    },
    handleTypes(value) {
      for (let index = 0; index < value.length; index++) {
        if (value[index] === "SURGICAL") {
          value[index] = "Centro cirúrgico";
        } else if (value[index] === "PROCEDURE") {
          value[index] = "Procedimento";
        } else if (value[index] === "APPOINTMENT") {
          value[index] = "Consulta";
        } else if (value[index] === "TELEMEDICINE") {
          value[index] = "Telemedicina";
        } else if (value[index] === "EXAM") {
          value[index] = "Exame";
        } else if (value[index] === "RETURN") {
          value[index] = "Retorno";
        }
      }

      return value;
    },
    handleLimitType(value) {
      switch (value) {
        case "day":
          return "dia";
        case "week":
          return "semana";
        case "month":
          return "mês";

        default:
          break;
      }
      this.dataToSave.constraint_config.limit = value;
    },
    close() {
      this.modalShow = false;
    },
    saveRestriction() {
      this.validationError = {
        doctors: false,
        healthPlans: false,
        types: false,
        days: false,
        amount: false,
        limit: false,
      };

      let hasErrors = false;

      console.log(this.dataToSave);

      if (this.dataToSave.doctors.length === 0) {
        this.validationError.doctors = true;
        hasErrors = true;
      }

      if (this.dataToSave.healthPlans.length === 0) {
        this.validationError.healthPlans = true;
        hasErrors = true;
      }

      if (this.dataToSave.constraint_config.types.length === 0) {
        this.validationError.types = true;
        hasErrors = true;
      }

      if (this.dataToSave.constraint_config.days.length === 0) {
        this.validationError.days = true;
        hasErrors = true;
      }

      if (!this.dataToSave.constraint_config.amount) {
        this.validationError.amount = true;
        hasErrors = true;
      }

      if (!this.dataToSave.constraint_config.limit) {
        this.validationError.limit = true;
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      this.dataToSave.clinic_id = this.clinic.id;

      this.api
        .saveScheduleConstraint(this.dataToSave)
        .then((response) => {
          this.$toast.success("Restrição salva com sucesso", {
            title: "Sucesso",
            variant: "success",
            solid: true,
          });

          this.getData();

          this.modalShow = false;
        })
        .catch((error) => {
          console.log(error);

          this.$toast.error("Erro ao salvar restrição", {
            title: "Erro",
            variant: "danger",
            solid: true,
          });
        });
    },
    handleRemotion(value, source) {
      if (source === "professionalFilterTable") {
        if (value.value === "all") {
          this.professionalFilterTable = [];
        }

        if (this.professionalFilterTable.length < this.doctorsOptions.length) {
          this.professionalFilterTable = this.professionalFilterTable.filter(
            (option) => option.value !== "all"
          );
        }
      } else if (source === "professionalAddRule") {
        if (value.value === "all") {
          this.tempDoctors = [];
        }

        if (this.tempDoctors.length < this.doctorsOptions.length) {
          this.tempDoctors = this.tempDoctors.filter((option) => option.value !== "all");
        }
      }
    },
    handleInput(value, source) {
      if (source === "professionalFilterTable") {
        if (
          value.value === "all" ||
          this.professionalFilterTable.length === this.doctorsOptions.length - 1
        ) {
          this.professionalFilterTable = this.doctorsOptions;
        }
      } else if (source === "professionalAddRule") {
        if (
          value.value === "all" ||
          this.tempDoctors.length === this.doctorsOptions.length - 1
        ) {
          this.tempDoctors = this.doctorsOptions;
        }
      }
    },
    async getDoctors() {
      const options = [];

      await this.api.getDoctors(this.clinic.id).then((response) => {
        if (response.data.doctors.length > 0) {
          options.push({
            label: "Todos os médicos",
            value: "all",
          });
        }

        for (let i = 0; i < response.data.doctors.length; i++) {
          options.push({
            label: response.data.doctors[i].name,
            value: response.data.doctors[i].id,
          });
        }
      });

      this.doctorsOptions = options;

      this.professionalOnRuleAdd = this.doctorsOptions;
      this.professionalFilterTable = this.doctorsOptions;
    },
    openModal() {
      this.dataToSave.id = null;

      this.modalShow = true;

      this.professionalOnRuleAdd = null;

      this.tempDoctors = [];
      this.tempHealthPlan = [];
      this.tempType = [];
      this.tempDays = [];
      this.tempLimit = [];
      this.amount = null;

      this.dataToSave.constraint_action = "warning";
    },
  },
  watch: {
    professionalFilterTable: {
      handler: function (value) {
        this.getData(value.map((item) => item.value));
      },
      deep: true,
    },
    tempDoctors(value) {
      this.dataToSave.doctors = value.map((item) => {
        if (item.value !== "all") {
          return item.value;
        }
      });
    },
    tempHealthPlan(value) {
      this.dataToSave.healthPlans = value.map((item) => {
        if (item.value !== "all") {
          return item.value;
        }
      });
    },
    tempType(value) {
      this.dataToSave.constraint_config.types = value.map((item) => {
        if (item.value !== "all") {
          return item.value;
        }
      });
    },
    tempDays(value) {
      this.dataToSave.constraint_config.days = value.map((item) => {
        if (item.value !== "all") {
          return item.value;
        }
      });
    },
    tempLimit(value) {
      this.dataToSave.constraint_config.limit = value.value;
    },
    amount(value) {
      this.dataToSave.constraint_config.amount = value;
    },
  },
};
</script>

<style lang="scss">
.tooltip-custom {
  background-color: #304388;
  font-family: Nunito Sans !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  .arrow::after,
  .arrow::before {
    border-top-color: #304388 !important;
    border-bottom-color: #304388 !important;
  }

  .popover-body {
    color: white !important;
  }

  strong {
    font-weight: 700;
  }
}
</style>

<style lang="scss" scoped>
.fakeTop {
  margin-top: -53px;
  margin-bottom: 30px;
}

.iconTooltip {
  display: inline-block;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

h3 {
  color: #0c1d59;
  font-size: 18px;
  font-weight: 700;
}

.alignBottom {
  display: flex;
  align-self: flex-end;
  color: #525c7a;

  p {
    margin-bottom: 5px;
  }
}

.table {
  margin: 20px 0;
  thead {
    td {
      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }
  tr,
  td {
    padding: 10px 6px;
    vertical-align: middle;
    color: var(--type-active);
    font-size: 14px;
    line-height: 20px;
  }
  .td-head {
    color: var(--dark-blue);
    font-size: 16px;
    line-height: 24px;
    border: none;
    background-color: var(--neutral-100);
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .pointer {
      cursor: pointer;
    }
  }
}

.pseudoMd3 {
  max-width: 178px;
}

.closeIcon svg {
  width: 24px;
  height: 24px;
}
</style>

<style>
.modalRule {
  border: 1px solid #c6ceeb !important;
  box-shadow: 0px 4px 4px 0px #0c1d591a;
  border-radius: 16px;
  border: 1px !important;
  padding: 12px 0px 0px 0px;
}

.modalRule .modal-header {
  font-family: "Nunito Sans";
  color: #0c1d59;
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;

  padding: 0px 24px 12px 24px;
}

.modalRule .modal-body {
  font-family: "Nunito Sans";
  color: #0c1d59;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 24px 0px 24px;
}

.modalRule button {
  font-family: Nunito Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.radioText label {
  color: #525c7a !important;
  font-family: Nunito Sans;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
</style>

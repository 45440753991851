<template>
    <div class="d-flex">
      <nav class="doctors d-flex">
          <div>
            <header class="doctors-title">Agendas:</header>
            <div
            class="doctor-list"
            @click="select({value: clinic.id, name: clinic.name, type: 'clinic'});config = false;getOutagesReasons('clinic')"
            v-bind:class="{ active: clinic.id === selected.value && config === false}"
            >
            <div class="fc-badge" />
            <p class="name">{{ clinic.name }}</p>
            </div>

            <div
              class="doctor-list"
              v-for="(doctor, i) in doctors"
              :key="i"
              @click="select(doctor); config = false; getOutagesReasons('doctor')"
              v-bind:class="{ active: doctor.value === selected.value && config === false}"
              >
              <Avatar
                size="40"
                :src="doctor?.picture"
                class="mr-3"
              />
              <p class="name">{{ doctor.name }}</p>
            </div>

            <div
              class="room-list"
              v-for="(room, i) in rooms"
              :key="room.value+i"
              @click="select(room); config = false; getOutagesReasons('room')"
              v-bind:class="{ active: room.value === selected.value && config === false}"
              >
              <p class="name">{{ room.name }}</p>
            </div>


          </div>

          <div
            v-can="'CaInd2'"
            class="configurations"
            v-bind:class="{ active: config }"
            @click="loadConfig()
            cleanOutagesReason()"
          >
            <Config /> Configurações
          </div>
      </nav>

      <main class="schedules" v-if="!config">
        <div class="doctor-selected-title">{{ selected.name }}</div>

        <div class="subtitle">
          {{ selected.type == 'clinic' ? 'Definir os dias em que a clínica não funcionará' : ''}}
          {{ selected.type == 'doctor' ? 'Definir os dias em que a este profissional estará indisponível' : ''}}
          {{ selected.type == 'room' ? 'Definir os dias em que a sala/setor não funcionará' : ''}}
          <Info id="subtitle-tooltip"/>
        </div>

        <b-tooltip target="subtitle-tooltip" triggers="hover">
            <p class="title">
              {{ selected.type == 'clinic' ? 'Indisponibilidade da clínica:' : ''}}
              {{ selected.type == 'doctor' ? 'Indisponibilidade do profissional:' : ''}}
              {{ selected.type == 'room' ? 'Indisponibilidade da sala/setor:' : ''}}
            </p>

            <p class="order-body">
                {{ selected.type == 'clinic' ? 'Defina os dias ou períodos em que a clínica não funcionará, atribua repetições, motivos e observações se necessário.' : ''}}
                {{ selected.type == 'doctor' ? 'Defina os dias ou períodos em que o profissional não irá trabalhar, defina se essa indisponibilidade é remunerada, atribua motivos e observações.' : ''}}
                {{ selected.type == 'room' ? 'Defina os dias ou períodos em que a sala/setor não funcionará, atribua repetições, motivos e observações se necessário.' : ''}}
            </p>
        </b-tooltip>

        <div class="outages">
          <div class="head d-flex">
            <span class="title">Indisponibilidade</span>
            <div class="d-flex date">
            <ChevronLeft @click="filterOutages('last')" />
            {{DatesFilter}}
            <ChevronRight @click="filterOutages('next')" />
            </div>
          </div>
          <div class="data-head">
            <p>Data</p>
            <p>Motivo</p>
            <p v-if="selected.type === 'clinic' || selected.type === 'room'">Repetição</p>
            <p v-else>Remunerado</p>
          </div>
          <div class="new-data-body">
            <ul class="new-items">
              <li class="new-item" v-for="(outage, idx) in outages" :key="idx">
                <div class="new-dates" :class="{'d-flex': outage.start !== outage.end}">
                    <p class="times-tag">{{moment(outage.start).format('DD/MM/YYYY')}}</p>
                    <p class="separator" v-if="outage.start !== outage.end">-</p>
                    <p class="times-tag" v-if="outage.start !== outage.end">{{moment(outage.end).format('DD/MM/YYYY')}}</p>
                </div>

                <div class="new-reason">
                  {{outage.reason.reason}}
                </div>

                <div class="new-receive">
                  <p v-if="selected.type === 'clinic' || selected.type === 'room'">
                    {{outage.repeat}}
                  </p>

                  <p v-else>
                    {{formatReceive(outage.receive)}}
                  </p>

                  <div class="options" v-if="userHasPermission('CaInd2') || userHasPermission('CaInd3')">
                    <div class="options">
                      <Dots class="more-icon" />
                      <div class="menu">
                        <b-button v-can="'CaInd2'" variant="link" @click="updateOutage(idx, outage)">Editar</b-button>
                        <b-button v-can="'CaInd3'" variant="link remove mb-0" v-b-modal.outage-exclude-modal @click="outageExclude = outage;outageExcludeType = 'outage'">Excluir</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="new-unavailability" v-if="creatingOutage">
                <div class="new-date-inputs">
                  <date-picker
                    format="DD[/]MM[/]YYYY"
                    :clearable="false"
                    :lang="langDatePicker"
                    placeholder="Selecione o intervalo de datas"
                    v-model="outagesForm.dates"
                    range
                  >
                  </date-picker>
                </div>

                <div class="new-reason">
                  <multiselect
                    placeholder="Selecione"
                    label="reason"
                    track-by="id"
                    v-model="outagesForm.reason"
                    :options="outagesReasons"
                    :showLabels="false"
                    :allowEmpty="false"
                    :searchable="false"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>
                </div>

                <div class="new-receive">
                  <multiselect
                  v-if="selected.type === 'clinic' || selected.type === 'room'"
                    placeholder="Selecione"
                    v-model="outagesForm.repeat"
                    :options="['Anual', 'Não há']"
                    :showLabels="false"
                    :allowEmpty="false"
                    :searchable="false"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>

                  <multiselect
                  v-else
                    placeholder="Selecione"
                    v-model="outagesForm.receive"
                    :options="['Sim', 'Não']"
                    :showLabels="false"
                    :allowEmpty="false"
                    :searchable="false"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>

                  <div class="close">
                    <Close @click="cancelCreateOutages"/>
                  </div>
                </div>
              </li>
            </ul>


            <div class="add-btn">
              <button v-can="'CaInd2'" v-if="!creatingOutage" @click="creatingOutage = true">
                Adicionar
              </button>

              <button v-can="'CaInd2'" v-else class="saveBtn" @click="createOutage">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </main>

      <main class="config" v-else>
        <div class="config-title">Configurações</div>
        <div class="subtitle">
          Configure e adicione novas categorias de indisponibilidade
          <Info id="config-tooltip"/>
        </div>

        <b-tooltip target="config-tooltip" triggers="hover">
            <p class="title">Motivos de indisponibilidade:</p>
            <p class="order-body">
                Defina os motivos (categorias) das indisponibilidades e defina se podem ser usadas na agenda da clínica, na agenda dos profissionais ou em ambas.
            </p>
        </b-tooltip>

        <div class="new-outages">
          <div class="new-head">
            <span class="new-title">Indisponibilidade</span>
          </div>

          <div class="new-data-head">
            <p>Motivos</p>
            <p>Agendas</p>
          </div>

          <div class="new-data-body">
            <ul>
              <li v-for="(reason, idx) in outagesReasons" :key="idx" class="new-view">
                <div class="new-reason">
                  {{reason.reason}}
                </div>

                <div class="new-receive">
                  {{reason.schedules.name}}

                  <div class="options">
                    <Dots class="more-icon" />

                    <div class="menu">
                      <b-button variant="link" @click="updateOutageReason(idx, reason)">Editar</b-button>
                      <b-button variant="link remove mb-0" v-b-modal.outage-exclude-modal @click="outageExclude = reason;outageExcludeType = 'reason'">Excluir</b-button>
                    </div>
                  </div>
                </div>
              </li>


              <li class="new-create" v-if="createReason">
                <div class="new-reason">
                  <input v-model="outagesReasonsForm.reason" placeholder="Nome do motivo" />
                </div>

                <div class="new-receive">
                  <multiselect
                    placeholder="Selecione"
                    label="name"
                    track-by="value"
                    v-model="outagesReasonsForm.schedules"
                    :options="reasonSchedulesOptions"
                    :showLabels="false"
                    :allowEmpty="false"
                    :searchable="false"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions">
                      Nenhuma opção
                    </template>

                    <template slot="noResult">
                      Nenhum resultado
                    </template>
                  </multiselect>

                  <div class="new-close">
                    <Close @click="cancelCreateOutagesReason"/>
                  </div>
                </div>
              </li>
            </ul>

            <div class="add-btn">
              <button v-if="!createReason"
              @click="createReason = true">
                Adicionar
              </button>

              <button v-else
              class="saveBtn"
              @click="createOutageReason">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </main>

    <b-modal
      id="outage-exclude-modal"
      hide-header
      hide-footer
      centered
      size="sm"
    >
      <div class="header d-flex flex-row justify-content-between">
        <p
          class="title"
          style="font-weight: 600; font-size: 18px; line-height: 28px; color: #525c7a;"
        >
          Excluir {{ outageExcludeType === 'reason' ? 'motivo de' :  '' }} indisponibilidade
        </p>
        <Close @click="$bvModal.hide('outage-exclude-modal')" />
      </div>

      <div class="body d-flex flex-column" align="center">
        <h6 v-if="outageExcludeType === 'reason'">
          Você tem certeza que deseja excluir o motivo <b>{{outageExclude.reason}}</b>?
          <br>Não será possível reverter essa ação.
        </h6>
        <h6 v-else>
          Você tem certeza que deseja a indisponibilidade <b>{{ outageExclude.reason.reason }}</b>
          do período de <b>{{ moment(outageExclude.start).format('DD/MM/YYYY') }}
          {{ outageExclude.start !== outageExclude.end ? 'a' + moment(outageExclude.end).format('DD/MM/YYYY') : ''}}</b>?
          <br>Não será possível reverter essa ação.
        </h6>
        <b-row class="mt-3" style="width: 100%; text-align: right;">
          <b-col>
            <b-button class="cancel"
            @click="$bvModal.hide('outage-exclude-modal')">
              Cancelar
            </b-button>
            <b-button class="confirm" variant="primary"
              v-if="outageExcludeType === 'reason'"
              @click="removeOutageReason(outageExclude.id)">
                Confirmar
            </b-button>
            <b-button class="confirm" variant="primary"
              v-else
              @click="removeOutage(outageExclude.id)">
              Confirmar
            </b-button>
          </b-col>
        </b-row>

      </div>

    </b-modal>
    </div>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'

export default {
  name: 'AgendaSettings',
  components: {
    Avatar: () =>  import('@/components/General/Avatar.vue'),
    ChevronDown: () =>  import('@/assets/icons/chevron-down.svg'),
    ChevronLeft: () =>  import('@/assets/icons/chevron-left.svg'),
    ChevronRight: () =>  import('@/assets/icons/chevron-right.svg'),
    Close: () =>  import('@/assets/icons/close.svg'),
    Config: () =>  import('@/assets/icons/config.svg'),
    Dots: () =>  import('@/assets/icons/dots.svg'),
    Info: () =>  import('@/assets/icons/info.svg'),
  },
  data() {
    return {
      DatesFilter: this.moment().format('YYYY'),
      user: null,
      clinic: JSON.parse(localStorage.getItem('clinic')),
      config: false,
      doctors: [],
      rooms: [],

      scheduleConfigId: null,

      outageExclude: {
        reason: {
          reason: null
        }
      },
      outageExcludeType: 'outage',

      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },

      outages: null,
      outagesOld: null,

      selected: { name: 'Carregando...', value: '1' },

      createReason: false,
      outagesReasons: {
        id: null,
        reason: null,
        schedules: null
      },

      outagesReasonsForm: {
        id: null,
        reason: null,
        schedules: null
      },
      reasonSchedulesOptions: [
        {value:'CLINIC_AND_PROFESSIONAL' , name:'Clínica e profissional'},
        {value:'PROFESSIONAL' , name:'Somente profissional'},
        {value:'CLINIC' , name:'Somente clínica'},
        {value:'ROOM' , name:'Sala/setor'},
      ],

      creatingOutage: false,
      outagesForm: this.getDefaultOutageForm(),
      defaultOutage: this.getDefaultOutageForm(),
      modalShow: false
    }
  },
  watch: {},

  mounted() {
    const clinicSelected = JSON.parse(localStorage.getItem('clinic'))

    const isLoading = this.$loading.show()

    this.clinic = clinicSelected

    this.api
      .getAllClinicRooms(clinicSelected.id)
      .then(({ data }) => {
        this.rooms = data.map(room => ({
          value: room.id,
          name: room.name,
          type: 'room',
        }));
      })
      .catch(error => {
        console.log(error)
      });


    this.api
      .getClinicDoctors(clinicSelected.id)
      .then(({ data }) => {
        const doctors = data.doctors.map(doctor => ({
          value: doctor.id,
          name: doctor.name,
          picture: doctor.picture,
          type: 'doctor'
        }))

        this.doctors = doctors
        this.selected = {
            value: clinicSelected.id,
            name: clinicSelected.name,
            type: 'clinic',
          }

        this.select(this.selected)
        this.getOutagesReasons('clinic')
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        isLoading.hide()
      })
  },
  computed: {
    exceptionDates() {
      return this.exceptionDays.map(day => day.date)
    },
    attributes() {
      return this.exceptionDates.map(date => ({
        highlight: true,
        dates: date
      }))
    }
  },
  methods: {
    userHasPermission,
    cleanOutagesReason() {
      this.outagesReasonsForm.reason = null
      this.outagesReasonsForm.schedules = null
    },
    filterOutages(type){
      if(type === 'next'){
        this.DatesFilter ++
      }
      else {
        this.DatesFilter --
      }

      this.outages = this.outagesOld.filter(d => {
        return this.moment(d.start).format('YYYY') === this.moment(this.DatesFilter, 'YYYY').format('YYYY')
      })
    },
    cancelCreateOutagesReason() {
      if(this.outagesReasonsForm.id)
        this.outagesReasons.push(this.outagesReasonsForm)

      this.outagesReasonsForm = {
        id: null,
        reason: null,
        schedules: null
      }

      this.createReason = false
    },
    getDefaultOutageForm() {
      return {
        id: null,
        professional_id: null,
        room_id: null,
        dates: null,
        reason: null,
        receive: null,
        repeat: null,
      }
    },
    clearOutagesForm() {
      this.outagesForm = this.getDefaultOutageForm();
    },

    cancelCreateOutages() {
      if(this.outagesForm.id){
        this.outagesForm.start = this.defaultOutage.start
        this.outagesForm.end = this.defaultOutage.end
        this.outagesForm.reason = this.defaultOutage.reason
        this.outagesForm.repeat = this.defaultOutage.repeat
        this.outages.push(this.outagesForm)
      }

      this.clearOutagesForm();

      this.creatingOutage = false
    },
    validateOutageForm() {
      if (!this.outagesForm.dates || !this.outagesForm.reason
        || (!this.selected.type === 'doctor' && !this.outagesForm.receive)
        || (!this.selected.type === 'clinic' && !this.outagesForm.repeat)
        || (!this.selected.type === 'room' && !this.outagesForm.repeat)
        ) {
        this.$toast.warning('Preencha todos os dados para continuar');
        return false;
      }
      return true;
    },
    createOutage() {
      if(!this.validateOutageForm()) return;

      if(this.outagesForm.id) {
        this.editOutage()
      }
      else {

        this.api
          .addOutages(this.clinic.id, {
            professional_id: this.selected.type === 'doctor' ? this.selected.value : null,
            room_id: this.selected.type === 'room' ? this.selected.value : null,
            date_start: this.moment(this.outagesForm.dates[0]).format('YYYY-MM-DD'),
            date_end: this.moment(this.outagesForm.dates[1]).format('YYYY-MM-DD'),
            reason_id: this.outagesForm.reason.id,
            receive: this.outagesForm.receive,
            repeat: this.outagesForm.repeat
          })
          .then(res => {
            this.creatingOutage = false

            this.clearOutagesForm();

            this.getOutages()
          })
          .catch(err => {
            this.$toast.error(err);
          })
      }
    },
    editOutage() {
      const isLoading = this.$loading.show()
      this.api
        .updateOutages(this.clinic.id, this.outagesForm)
        .then(res => {
          this.creatingOutage = false

          this.clearOutagesForm();

          this.getOutages()
          this.$toast.success('Indisponibilidade atualizada com sucesso')
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    createOutageReason() {
      if(this.outagesReasonsForm === null || this.outagesReasonsForm.schedules === null){
        alert('Preencha todos os dados para continuar')
        return
      }

      if(this.outagesReasonsForm.id){
        this.api
          .updateOutagesReasons(this.clinic.id, {
            id: this.outagesReasonsForm.id,
            reason: this.outagesReasonsForm.reason,
            schedules: this.outagesReasonsForm.schedules.value
            })
          .then(res => {
            this.createReason = false

            this.outagesReasonsForm = {
              id: null,
              reason: null,
              schedules: null
            }

            this.loadConfig()
            })
            .catch(console.log)
      }
      else {
        this.api
          .addOutagesReasons(this.clinic.id, {
            reason: this.outagesReasonsForm.reason,
            schedules: this.outagesReasonsForm.schedules.value
          })
          .then(res => {
            this.createReason = false

            this.outagesReasonsForm = {
              id: null,
              reason: null,
              schedules: null
            }

            this.loadConfig()
          })
          .catch(console.log)
      }
    },
    updateOutage(idx, value) {
      this.defaultOutage = value
      this.outagesForm.id = value.id
      this.outagesForm.professional_id = this.selected.value
      this.outagesForm.dates = [
        new Date(value.start.split('-')),
        new Date(value.end.split('-'))
      ]
      this.outagesForm.reason = value.reason
      this.outagesForm.receive = this.formatReceive(value.receive)
      this.outagesForm.repeat = value.repeat


      this.outages.splice(idx, 1)
      this.creatingOutage = true
    },
    updateOutageReason(idx, value) {
      this.outagesReasonsForm = {...value}

      this.outagesReasons.splice(idx, 1)
      this.createReason = true
    },
    removeOutage(value) {
      this.api
        .removeOutages(this.clinic.id, { id: value })
        .then(res => {
          this.$toast.success('Indisponibilidade excluida com sucesso')
          this.getOutages();
        })
        .catch(err => {
          this.$toast.error(err);
        })
        .finally(() => {
          this.$bvModal.hide('outage-exclude-modal');
        })
    },
    removeOutageReason(value) {
      this.api
        .removeOutagesReasons(this.clinic.id, { id: value })
        .then(res => {
          this.$toast.success('Motivo de indisponibilidade excluído com sucesso')
          this.loadConfig();
        })
        .catch(err => {
          this.$toast.error(err);
        })
        .finally(() => {
          this.$bvModal.hide('outage-exclude-modal');
        })
    },
    translateReasonSchedules(value) {
      var res = null;

      switch (value) {
        case 'CLINIC_AND_PROFESSIONAL':
            res = {value:'CLINIC_AND_PROFESSIONAL' , name:'Clínica e profissional'}
          break;
        case 'PROFESSIONAL':
            res = {value:'PROFESSIONAL' , name:'Somente profissional'}
          break;
        case 'CLINIC':
            res = {value:'CLINIC' , name:'Somente clínica'}
          break;
        case 'ROOM':
            res = {value:'ROOM' , name:'Somente sala/setor'}
          break;
        default:
          break;
      }

      return res
    },
    getOutagesReasons(type){
      this.api
        .getOutagesReasons(this.clinic.id, type)
        .then(res => {

          const resData = res.data.map(data => ({
            id: data.id,
            reason: data.reason,
            schedules: this.translateReasonSchedules(data.schedules)
          }))

          this.outagesReasons = resData
        })
        .catch(console.log)
    },
    loadConfig() {
      const isLoading = this.$loading.show()
      this.config = true

      this.api
        .getOutagesReasons(this.clinic.id)
        .then(res => {

          const resData = res.data.map(data => ({
            id: data.id,
            reason: data.reason,
            schedules: this.translateReasonSchedules(data.schedules)
          }))
          this.outagesReasons = resData
        })
        .catch(console.log)
        .finally(() => {
          isLoading.hide()
        })
    },
    formatReceive(value){
      var res = 0

      switch (value) {
        case 1:
          res = 'Sim'
          break;
        case 0:
          res = 'Não'
          break

        default:
          res = value
          break;
      }
      return res
    },
    select(val) {
      this.selected = val
      this.creatingOutage = false
      this.createReason = false

      this.getOutages()
    },
    getOutages(){
      const isLoading = this.$loading.show()

      this.api
        .getOutagesWithType(this.clinic.id, this.selected.value, this.selected.type)
        .then(res => {
          this.outagesOld = res.data

          this.outages = this.outagesOld.filter(d => {
            return this.moment(d.start).format('YYYY') === this.moment(this.DatesFilter, 'YYYY').format('YYYY')
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    setCreator(date) {
      this.creatingIn = date

      setTimeout(() => {
        document.querySelector('#startTmpField').focus()
      }, 300)
    },
    filterByDay(day) {
      return this.doctorDates.filter(date => date.day === day)
    },
    resetModalFields() {
      this.exceptionDays = []
      this.exceptionTimes = []
    },
    onDayClick(day) {
      if (day.isDisabled) return

      const idx = this.exceptionDays.findIndex(d => d.id === day.id)

      if (idx >= 0) {
        this.exceptionDays.splice(idx, 1)
      } else {
        this.exceptionDays.push({
          id: day.id,
          date: day.date
        })
      }
    },
    addTmpTime() {
      this.exceptionTimes.push({
        start: '',
        end: ''
      })
    },
    removeTmpTime(idx) {
      this.exceptionTimes.splice(idx, 1)
    },
    toggleModal() {
      this.modalShow = !this.modalShow
      this.resetModalFields()
    },
    parseTimes(obj) {
      return obj
        .map(el => {
          const start = el.start.split(':')
          const end = el.end.split(':')

          start.pop()
          end.pop()

          return {
            id: el.id,
            start: start.join(':'),
            end: end.join(':')
          }
        })
        .sort((a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0))
    },
    groupBy(collection, property) {
      var i = 0
      var val
      var index
      var values = []
      var result = []
      for (; i < collection.length; i++) {
        val = collection[i][property]
        index = values.indexOf(val)
        if (index > -1) result[index].push(collection[i])
        else {
          values.push(val)
          result.push([collection[i]])
        }
      }
      return result
    }
  }
}
</script>

<style lang="scss">

#outage-exclude-modal {
  .modal-dialog {
    display: flex;
    justify-content: center;
  }
  .modal-content {
    max-width: 448px !important;

    .modal-body {
      padding: 0 !important;

      .header {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .title {
          font-family: 'Nunito Sans';
          font-weight: 600;
          font-size: 18px;
          margin: 0;
          color: var(--type-active);
        }

        svg {
          width: 24px;
          height: 24px;
          fill: var(--neutral-500);
        }
      }

      .body {
        padding: 24px;
        align-items: center;

        .mt-3, .my-3 {
          margin-top: 0 !important;
        }

        h6 {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
          text-align: justify;
          margin-top: 14px;
          margin-bottom: 56px;
        }

        b {
          color: #305BF2;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
        }

        .cancel {
          color: #F63220;
          background-color: transparent;
          border: none;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }

        .confirm {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}


    .tooltip {
      .tooltip-inner {
        .title {
          margin-bottom: 10px;
          font-size: 18px;
        }

        .order-body {
          max-width: 648px !important;
          font-size: 16px;;
        }

        .actions {
          width: 100px !important;
          max-height: 99px !important;
          color: #305BF2;
          padding-left: 5px;
          text-align: left;

          .edit {
            margin-bottom: 15px;
            margin-top: 5px;
            cursor: pointer;
          }

          .exclude {
            color: #F63220;
            margin-bottom: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .mx-datepicker-main {
      width: auto !important;
    }

    button {
        padding: 0;
        border: none;
        background-color: transparent;

        &.interval-add {
        font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 8px;
        color: #305bf2;
        }
    }

    .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 24px;
        color: var(--dark-blue);
        margin-bottom: 27px;
    }

    .with-border {
    margin-bottom: 8px;
    }

    .doctors {
    border-right: 1px solid #d9dff2;
    padding-right: 0;
    width: 30%;
    flex-direction: column;
    justify-content: space-between;

    .doctors-title {
        padding: 24px;
        font-size: 18px;
        font-weight: 700;
        color: var(--dark-blue);
        border-bottom: 1px solid #d9dff2;
    }

    .doctor-list, .room-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        cursor: pointer;

        padding: 16px 20px;

        border-bottom: 1px solid #d9dff2;
        border-left: 3px solid transparent;

        transition: all ease 0.3s;

        &:hover {
        background-color: rgba(0, 0, 0, 0.02);
        border-left: 3px solid #ffb27a;
        }

        &.active {
        border-left: 3px solid #ff6b00;
        }

        .fc-badge {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          border: 1px solid #0C1D59;
          background-color: #0C1D59;
          margin-right: 9px;
        }

        .name {
        font-family: 'Nunito Sans';
        font-size: 16px;
        line-height: 150%;
        color: #525c7a;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }
    }

    .room-list {
      padding: 24px;
    }

    .configurations {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        bottom: 0px;

        cursor: pointer;

        padding: 16px 20px;

        border-top: 1px solid #d9dff2;
        border-bottom: 1px solid #d9dff2;
        border-left: 3px solid transparent;

        transition: all ease 0.3s;

        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #525C7A;

        &:hover {
        background-color: rgba(0, 0, 0, 0.02);
        border-left: 3px solid #ffb27a;
        }

        &.active {
        border-left: 3px solid #ff6b00;
        }

        svg {
          margin-right: 15px;
        }
    }
    }


    .schedules {
    padding: 48px;
    width: 80%;

    .doctor-selected-title {
        font-family: 'Red Hat Display';
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 30px;
        color: #525c7a;
    }

    .subtitle {
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #525c7a;
        margin-bottom: 16px;

        svg {
          width: 20px;
          height: 20px;
          margin-left: 5px;
          cursor: pointer;
        }
    }

    .outages {
      border: 1px solid #D9DFF2;
      box-sizing: border-box;
      border-radius: 8px;

          .add-btn {
            text-align: center;
            margin: 20px 0px;

            button {
              width: 220px;
              height: 38px;
              border: 2px solid #305BF2;
              box-sizing: border-box;
              border-radius: 8px;

              font-family: Nunito Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
              text-align: center;
              color: #305BF2;

              &.saveBtn {
                color: #305BF2;
                border: 2px solid #305BF2;
              }

              &.cancel {
                color: #FFEBCC;
                border: 2px solid#ffdccc;
              }
            }
          }

      .head {
        height: 70px;
        padding: 0px 20px;
        justify-content: space-between;
        align-items: center;
        color: #525C7A;

        .title {
          margin: 0px;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
        }

        .date {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;

          svg {
            width: 35px;
            height: 25px;
            stroke: #305BF2;
            cursor: pointer;
          }
        }
      }

      .data-head {
        padding: 10px 20px;
        display: grid;
        gap: 24px;
        grid-template-columns: 7fr 4fr 4fr;
        align-items: flex-start;
        background: #F4F5FB;
        color: #525C7A;
        border-top: 1px solid #D9DFF2;
        border-bottom: 1px solid #D9DFF2;

        p {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .new-data-body {

        .new-items {
          padding: 0px;

          .new-item {
            display: grid;
            gap: 24px;
            grid-template-columns: 7fr 4fr 4fr;
            list-style: none;
            align-items: center;
            align-items: center;
            border-bottom: 1px solid #D9DFF2;
            padding: 24px;

            .new-dates {
              width: 100%;
              align-items:center;

              .separator {
                margin: 0px 5px;
              }

              .times-tag {
                padding: 8px 16px;
                border-radius: 50px;
                background-color: #d9dff2;
                color: #000;
                width: fit-content;

                &.active {
                  background: #ffebcc;
                }
              }
            }

            .new-receive {
              display: flex;
              justify-content: space-between;

              .options {
                cursor: pointer;
                width: 24px;
                height: 28px;
                position: relative;
                display: inline-block;

                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  width: 160px;
                  position: absolute;
                  top: 28px;
                  right: 0;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 24px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .btn {
                    font-weight: 600;
                    margin-bottom: 16px;

                    &.remove {
                      color: var(--states-error);
                    }
                  }
                }

                &:hover {
                  .more-icon {
                    transform: rotate(90deg);
                  }

                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }
            }
          }

          .new-unavailability {
            display: grid;
            grid-template-columns: 7fr 4fr 4fr;
            gap: 24px;
            padding: 20px 20px 0;

            .new-date-inputs {
              .mx-datepicker {
                height: 38px;
                width: 100%;
              }
              .mx-datepicker-range {
                height: 38px;
                width: 100%;
              }
              .mx-input-wrapper {
                height: 38px;
                width: 100%;
              }
              .mx-input {
                text-align: center;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                height: 38px;
                width: 100%;
              }
            }

            .new-reason {
              .multiselect__tags {
                min-height: 38px;
                background: #FFFFFF;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
              }

              .multiselect__content-wrapper {
                width: 100%;
              }
            }

            .new-receive {
              display: flex;
              flex-direction: row;
              gap: 8px;

              .multiselect__tags {
                min-height: 38px;
                background: #FFFFFF;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                width: 100%;
              }

              .multiselect__content-wrapper {
                width: 100%;
              }

              .close {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }

      .data-body {
        padding: 0px 20px;

        .items {
          padding: 0px;
          margin: 10px 0px;

          .item {
            list-style: none;
            align-items: center;
            display: grid;
            grid-template-columns: 35% 40% 25%;
            align-items: center;
            border-bottom: 1px solid #D9DFF2;
            padding: 20px 0px;

            .dates {
              align-items:center;

              .separator {
                margin: 0px 5px;
              }

              .times-tag {
                padding: 8px 16px;
                border-radius: 50px;
                background-color: #d9dff2;
                color: #000;
                width: fit-content;

                &.active {
                  background: #ffebcc;
                }
              }
            }

            .receive {
              justify-content: space-between;

              .options {
                cursor: pointer;
                width: 24px;
                height: 28px;
                position: relative;
                display: inline-block;

                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  width: 160px;
                  position: absolute;
                  top: 28px;
                  right: 0;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 24px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .btn {
                    font-weight: 600;
                    margin-bottom: 16px;

                    &.remove {
                      color: var(--states-error);
                    }
                  }
                }

                &:hover {
                  .more-icon {
                    transform: rotate(90deg);
                  }

                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }
            }
          }

          .create {
            list-style: none;
            align-items: center;
            display: grid;
            grid-template-columns: 35% 40% 25%;
            align-items: center;
            border-bottom: 1px solid #D9DFF2;
            padding: 20px 0px;

            .date-inputs {
              input {
                text-align: center;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
              }
            }

              .reason {
                .multiselect {
                  border: 1px solid #C6CEEB;
                  box-sizing: border-box;
                  border-radius: 8px;
                  width: 80%;

                  .multiselect__tags {
                    padding-right: 0px;
                  }

                  .multiselect__single {
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    color: var(--type-active);
                  }
                }
              }

              .multiselect {
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                width: 60%;

                .multiselect__tags {
                  padding-right: 0px;
                }

                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: var(--type-active);
                }
              }

            .receive {
              justify-content: space-between;

              .multiselect {
                min-width: 130px !important;
                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: var(--type-active);
                }
              }

              .close {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
    }

    .config {
      padding: 48px;
      width: 80%;

    .config-title {
        font-family: 'Red Hat Display';
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 30px;
        color: #525c7a;
    }

    .subtitle {
        font-family: 'Nunito Sans';
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: #525c7a;
        margin-bottom: 16px;

        svg {
          width: 20px;
          height: 20px;
          margin-left: 5px;
          cursor: pointer;
        }
    }

    /* .outages {
      border: 1px solid #D9DFF2;
      box-sizing: border-box;
      border-radius: 8px;

      .head {
        height: 70px;
        padding: 0px 20px;
        justify-content: space-between;
        align-items: center;
        color: #525C7A;

        .title {
          margin: 0px;
          font-family: Red Hat Display;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
        }
      }

      .data-head {
        padding: 10px 20px;
        display: grid;
        gap: 24px;
        grid-template-columns: 70% 30%;
        align-items: flex-start;
        background: #F4F5FB;
        color: #525C7A;

        p {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .data-body {
        padding: 10px 20px;

        ul {
          padding: 0px;
          margin: 10px 0px;

          .view {
            list-style: none;
            align-items: center;
            display: grid;
            grid-template-columns: 70% 30%;
            align-items: center;
            border-bottom: 1px solid #D9DFF2;
            padding: 20px 0px;

            .dates {
              align-items:center;

              .separator {
                margin: 0px 5px;
              }

              .times-tag {
                padding: 8px 16px;
                border-radius: 50px;
                background-color: #d9dff2;
                color: #000;
                width: fit-content;

                &.active {
                  background: #ffebcc;
                }
              }
            }

            .receive {
              justify-content: space-between;

              .options {
                cursor: pointer;
                width: 24px;
                height: 28px;
                position: relative;
                display: inline-block;

                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  width: 160px;
                  position: absolute;
                  top: 28px;
                  right: 0;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 24px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .btn {
                    font-weight: 600;
                    margin-bottom: 16px;

                    &.remove {
                      color: var(--states-error);
                    }
                  }
                }

                &:hover {
                  .more-icon {
                    transform: rotate(90deg);
                  }

                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }
            }
          }
        }


          .create {
            list-style: none;
            align-items: center;
            display: grid;
            grid-template-columns: 70% 30%;
            align-items: center;
            border-bottom: 1px solid #D9DFF2;
            padding: 20px 0px;

              input {
                width: 260px;
                height: 42px;
                padding: 15px;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;

                font-family: Nunito Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: #8696AC;
              }

              input:focus {
                outline: none !important;
                border-color: #305BF2;
              }

              .multiselect {
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                width: 220px;

                .multiselect__tags {
                  padding-right: 0px;
                }

                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: #8696AC;
                }
              }

            .receive {
              justify-content: space-between;

              .close {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

        .add-btn {
          text-align: center;
          margin: 20px 0px;

          button {
            width: 220px;
            height: 52px;
            border: 2px solid #305BF2;
            box-sizing: border-box;
            border-radius: 8px;

            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #305BF2;

            &.saveBtn {
              color: #305BF2;
              border: 2px #305BF2 #305BF2;
            }

            &.cancel {
              color: #FFEBCC;
              border: 2px solid#ffdccc;
            }
          }
        }
      }
    } */

    .new-outages {
      border: 1px solid #D9DFF2;
      box-sizing: border-box;
      border-radius: 8px;

      .new-head {
        padding: 0px 20px;
        justify-content: space-between;
        align-items: center;
        color: #525C7A;

        .new-title {
          margin: 24px 0;
          font-family: 'Red Hat Display';
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01em;
        }
      }

      .new-data-head {
        padding: 10px 20px;
        display: grid;
        grid-template-columns: 65% 35%;
        align-items: flex-start;
        background: #F4F5FB;
        color: #525C7A;
        border-top: 1px solid #D9DFF2;
        border-bottom: 1px solid #D9DFF2;

        p {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .new-data-body {

        ul {
          padding: 0px;




          .new-view {
            list-style: none;
            align-items: center;
            display: grid;
            grid-template-columns: 65% 35%;
            align-items: center;
            border-bottom: 1px solid #D9DFF2;
            padding: 32px 24px;

            /* .dates {
              align-items:center;

              .separator {
                margin: 0px 5px;
              }

              .times-tag {
                padding: 8px 16px;
                border-radius: 50px;
                background-color: #d9dff2;
                color: #000;
                width: fit-content;

                &.active {
                  background: #ffebcc;
                }
              }
            } */

            .new-receive {
              display: flex;
              justify-content: space-between;

              .options {
                cursor: pointer;
                width: 24px;
                height: 28px;
                position: relative;
                display: inline-block;

                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  width: 160px;
                  position: absolute;
                  top: 28px;
                  right: 0;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 24px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .btn {
                    font-weight: 600;
                    margin-bottom: 16px;

                    &.remove {
                      color: var(--states-error);
                    }
                  }
                }

                &:hover {
                  .more-icon {
                    transform: rotate(90deg);
                  }

                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }
            }
          }


          .new-create {
            display: grid;
            grid-template-columns: 1.3fr 1.15fr;
            padding: 20px 20px 0;
            gap: 159px;

            .new-reason {
              input {
                padding-left: 16px;
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                height: 38px;
                color: var(--type-active);
              }

              input:focus {
                outline: none !important;
                border-color: #305BF2;
              }
            }

            .new-receive {
              display: flex;
              flex-direction: row;
              gap: 24px;

              .multiselect__tags {
                background: #FFFFFF;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                height: 38px;
                width: 100%;

                .multiselect__single {
                  font-size: 16px;
                  line-height: 150%;
                  color: var(--type-active);
                }
              }

              .multiselect__content-wrapper {
                .multiselect__content {
                  .multiselect__element {
                    .multiselect__option {
                      color: var(--type-active);
                      .multiselect__option--highlight {
                        font-size: 16px;
                        line-height: 150%;
                      }
                    }
                  }
                }
              }

              .new-close {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                  fill: #A4B1DF;
                  width: 20px;
                  height: 20px;
                }
              }

            }
          }

/*           .create {
            list-style: none;
            align-items: center;
            display: grid;
            grid-template-columns: 2fr;
            align-items: center;
            padding: 20px 0px;

              input {
                width: 260px;
                height: 42px;
                padding: 15px;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;

                font-family: Nunito Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: #8696AC;
              }

              input:focus {
                outline: none !important;
                border-color: #305BF2;
              }

              .multiselect {
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                width: 220px;

                .multiselect__tags {
                  padding-right: 0px;
                }

                .multiselect__single {
                  font-family: Nunito Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: #8696AC;
                }
              }

            .receive {
              justify-content: space-between;

              .close {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          } */
        }

        .add-btn {
          text-align: center;
          margin: 20px 0px;

          button {
            width: 220px;
            height: 38px;
            border: 2px solid #305BF2;
            box-sizing: border-box;
            border-radius: 8px;

            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #305BF2;

            &.saveBtn {
              color: #305BF2;
              border: 2px #305BF2 #305BF2;
            }

            &.cancel {
              color: #FFEBCC;
              border: 2px solid#ffdccc;
            }
          }
        }
      }
    }
    }
</style>

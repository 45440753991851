<template>
  <div>
    <div class="patient-order">
      <p class="title">
        Atendimento de pacientes
        <Info id="order-tooltip" />
      </p>

      <b-tooltip target="order-tooltip" triggers="hover">
        <p class="title">Atendimento de pacientes:</p>
        <p class="order-body">
          Ao configurar em atender pacientes por ordem de chegada, os pacientes
          na agenda e na visão geral do médico serão ordenados por ordem de
          chegada. Ao selecionar horário marcado, a ordem dos atendimentos se
          mantém.
        </p>
      </b-tooltip>

      <div class="order-body">
        <p>Atender pacientes por</p>
        <multiselect
          v-model="scheduleOrder"
          :options="orderOptions"
          track-by="value"
          label="name"
          @input="saveAttendanceType"
          :showLabels="false"
          :searchable="false"
          :allowEmpty="false"
          placeholder="Selecione"
          class="with-border order-options"
          :disabled="!canEdit"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>

          <template slot="noOptions">
            Nenhuma opção
          </template>

          <template slot="noResult">
            Nenhum resultado
          </template>
        </multiselect>
      </div>
    </div>

    <div class="tabs">
      <span class="tab" :class="{ 'active': currentTab == 'funcionamento' }" @click="currentTab = 'funcionamento'">Horários semanais</span>
      <span class="tab" :class="{ 'active': currentTab == 'excecoes' }" @click="currentTab = 'excecoes'">Exceções</span>
    </div>

    <div class="tabs-content">
      <div v-if="currentTab == 'funcionamento'">
        <main class="schedules-content">
          <div class="title">
            Defina os dias e horários de funcionamento da sua agenda
            <Info id="days-tooltip" />
          </div>

          <b-tooltip target="days-tooltip" triggers="hover">
            <p class="title">Funcionamento da clínica:</p>
            <p class="order-body">
              Defina os horários em que a clínica atende. Esse horário será usado
              para mostrar as horas na agenda e definir o início e o fim dos
              períodos.
            </p>
          </b-tooltip>
          
          <div class="times">
            <div class="times-default">
              <div class="times-list" v-for="date in dates" :key="date.title">
                <div class="col">
                  <div
                    class="times-tag"
                    v-bind:class="{ active: statusTmp[date.value] }"
                  >
                    {{ date.title }}
                  </div>
                </div>
                <div class="times-data-inputs">
                  <div class="data-inputs">
                    <div>
                      <span>Horário de abertura</span>
                      <the-mask
                        :mask="['##:##']"
                        masked
                        id="startTmpField"
                        class="form-control"
                        :class="{ disabled: !statusTmp[date.value] }"
                        v-model="startTmp[date.value]"
                        @change.native="updateTimes(date.value)"
                        :disabled="!canEdit"
                      />
                    </div>
                  </div>
                  <div style="margin-top: 25px">-</div>
                  <div class="data-inputs">
                    <div>
                      <span>Horário de fechamento</span>
                      <the-mask
                        :mask="['##:##']"
                        masked
                        class="form-control"
                        :class="{ disabled: !statusTmp[date.value] }"
                        v-model="endTmp[date.value]"
                        @change.native="updateTimes(date.value)"
                        :disabled="!canEdit"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p class="operation-status" v-if="statusTmp[date.value]">Ativo</p>
                  <p class="operation-status" v-else>Inativo</p>
                  <toggle-button
                    class="statusBtn"
                    :sync="true"
                    :height="32"
                    :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                    v-model="statusTmp[date.value]"
                    @input="updateTimes(date.value)"
                    :disabled="!canEdit || updating"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div v-if="currentTab == 'excecoes'">
        <ScheduleExceptionView           
          :scheduleConfigId="this.scheduleConfigId"
          :exceptions="clinicExceptions"
          :hideDuration="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { userHasPermission } from '@/utils/localStorageManager'

export default {
  name: 'Operation',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Info: () => import('@/assets/icons/info.svg'),
    ScheduleExceptionView: () => import('@/modules/scheduleConfig/components/ScheduleExceptionView'),
  },
  data() {
    return {
      currentTab: 'funcionamento',
      canEdit: userHasPermission('CaFunc2'),
      user: null,
      clinic: JSON.parse(localStorage.getItem('clinic')),
      scheduleConfigId: null,
      days: [],
      updating: false,

      // creatingIn: '',
      startTmp: {
        'DOMINGO': '08:00',
        'SEGUNDA-FEIRA': '08:00',
        'TERCA-FEIRA': '08:00',
        'QUARTA-FEIRA': '08:00',
        'QUINTA-FEIRA': '08:00',
        'SEXTA-FEIRA': '08:00',
        'SABADO': '08:00'
      },
      endTmp: {
        'DOMINGO': '00:00',
        'SEGUNDA-FEIRA': '00:00',
        'TERCA-FEIRA': '00:00',
        'QUARTA-FEIRA': '00:00',
        'QUINTA-FEIRA': '00:00',
        'SEXTA-FEIRA': '00:00',
        'SABADO': '00:00'
      },
      statusTmp: {
        'DOMINGO': false,
        'SEGUNDA-FEIRA': false,
        'TERCA-FEIRA': false,
        'QUARTA-FEIRA': false,
        'QUINTA-FEIRA': false,
        'SEXTA-FEIRA': false,
        'SABADO': false
      },

      scheduleOrder: null,
      orderOptions: [
        { name: 'Horário marcado', value: 'SCHEDULED' },
        { name: 'Ordem de chegada à clínica', value: 'ARRIVAL' }
      ],

      dates: [
        {
          title: 'Domingo',
          value: 'DOMINGO'
        },
        {
          title: 'Segunda',
          value: 'SEGUNDA-FEIRA'
        },
        {
          title: 'Terça',
          value: 'TERCA-FEIRA'
        },
        {
          title: 'Quarta',
          value: 'QUARTA-FEIRA'
        },
        {
          title: 'Quinta',
          value: 'QUINTA-FEIRA'
        },
        {
          title: 'Sexta',
          value: 'SEXTA-FEIRA'
        },
        {
          title: 'Sábado',
          value: 'SABADO'
        }
      ],

      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },

      clinicExceptions: [],

      exceptionDays: [],
      exceptionTimes: {
        days: '',
        start: '',
        end: ''
      },

      modalShow: false
    }
  },
  mounted() {
    this.getConfig();
    this.api
      .getAttendanceType(this.clinic.id)
      .then(res => {
        if (res.data === 'ARRIVAL') {
          this.scheduleOrder = {
            name: 'Ordem de chegada à clínica',
            value: 'ARRIVAL'
          }
        } else {
          this.scheduleOrder = { name: 'Horário marcado', value: 'SCHEDULED' }
        }
      })
      .catch(err => {
        console.log('Err', err)
      })

    this.api
      .getExceptionDate(this.clinic.id)
      .then(res => {
        this.clinicExceptions = res.data
      })
      .catch(err => console.log('Err', err))
  },
  computed: {
    exceptionDates() {
      return this.exceptionDays.map(day => day.date)
    },
    attributes() {
      return this.exceptionDates.map(date => ({
        highlight: true,
        dates: date
      }))
    }
  },
  methods: {
    ...mapState('scheduleConfig', ['getClinicDays']),
    async getConfig() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getClinicScheduleConfig(this.clinic.id);
          const {
            id: scheduleConfigId,
            days,
        } = data.configs

          this.scheduleConfigId = scheduleConfigId;
          this.days = days;

        await this.createConfigDaysIfNeeded();

        this.days.forEach(date => {
            this.startTmp[date.day] = date.schedule_config_day_intervals[0].start_time
            this.endTmp[date.day] = date.schedule_config_day_intervals[0].end_time
            this.statusTmp[date.day] = !!date.status
          })

      } catch(err) {
          console.log(err)
      } finally {
          isLoading.hide()
      }
    },
    async createConfigDaysIfNeeded() {
      try {
        for (const date of this.dates){
          const foundDay = this.days.find(day => day.day === date.value);
          if(!foundDay) {
            const interval = {
              day: date.value,
              schedule_config_id: this.scheduleConfigId,
              start_time: '08:00',
              end_time: '00:00',
              status: false,
            };

            const response = await this.api.createScheduleConfigDayInterval(interval)

            this.days.push({
              ...interval,
              id: response.data.schedule_config_day_id,
              schedule_config_day_intervals: [{...response.data}],
            });
          }
        };
      } catch(err) {
        console.log('Failed to create day interval', err);
      }
    },
    isInvalidInterval(dayOfWeekStr) {
      const newStartTime = this.moment(this.startTmp[dayOfWeekStr], 'HH:mm');
      const newEndTime = this.moment(this.endTmp[dayOfWeekStr], 'HH:mm');

      if(newEndTime <= newStartTime)
        return 'Horário final deve ser maior que o inicial';

      if (!newStartTime.isValid() || newStartTime > this.moment().endOf('day')) {
        return 'Horário inicial inválido!\nDefina um período entre 00:00 e 23:59.';
      }

      if(!newEndTime.isValid() || newEndTime > this.moment().endOf('day'))
        return 'Horário final inválido!\nDefina um período entre 00:00 e 23:59.';

      if(!Object.keys(this.statusTmp).find(key => this.statusTmp[key]))
        return 'A clinica deve funcionar ao menos 1 dia por semana!';
    },
    updateTimes(date) {
      this.updating = true;
      try {
        const isInvalid = this.isInvalidInterval(date);
        const day = this.days.find(day => day.day === date);
        if(isInvalid) {
          this.statusTmp[date] = day.status;
          return this.$toast.error(isInvalid);
        }

        const interval = {
          ...day.schedule_config_day_intervals[0],
          start_time: this.startTmp[date],
          end_time: this.endTmp[date],
          status: this.statusTmp[date],
        };
        this.api.updateScheduleConfigDayInterval(interval.id, { ...interval })
          .then(res => {
            this.$toast.success('Atualizado com sucesso')
            this.getClinicDays();
          })
          .catch(err => console.log(err))
          .finally(() => { this.updating = false });
      } catch (err) {
        this.updating = false;
      }

    },
    saveAttendanceType() {
      const isLoading = this.$loading.show()

      this.api
        .saveAttendanceType(this.clinic.id, {
          status: this.scheduleOrder.value
        })
        .then(res => {
          console.log(res.data)
        })
        .catch(err => {
          console.log('Err', err)
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    resetModalFields() {
      this.exceptionDays = []
      this.exceptionTimes = []
    },
    toggleModal() {
      this.modalShow = !this.modalShow
      this.resetModalFields()
    },
    async saveExceptions() {
      const isLoading = this.$loading.show()
      try {
        await this.api.addExceptionDate(this.clinic.id, {
          dateStart: this.dayjs(this.exceptionTimes.days[0]),
          dateEnd: this.dayjs(this.exceptionTimes.days[1]),
          start: this.exceptionTimes.start,
          end: this.exceptionTimes.end,
          scheduleConfigId: this.scheduleConfigId
        })
         this.resetModalFields()
        this.getExceptions()
        this.modalShow = false
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getExceptions() {
      this.api
        .getExceptionDate(this.clinic.id)
        .then(res => {
          this.clinicExceptions = res.data
        })
        .catch(err => console.log('Err', err))
    },
    async deleteExceptions(date) {
      const isLoading = this.$loading.show()

      await this.api
        .destroyClinicException(this.clinic.id, date.id)
        .then(res => {
          this.clinicExceptions = res.data
        })
        .catch(err => console.log('Err', err))

      isLoading.hide()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/modules/scheduleConfig/styles/tabs.scss';
</style>

<style lang="scss">
.tooltip {
  opacity: 1 !important;

  .arrow::before {
    border-top-color: #ffffff !important;
    border-bottom-color: #ffffff !important;
  }

  .tooltip-inner {
    background-color: #ffffff !important;
    color: #525c7a !important;
    max-width: none !important;
    box-shadow: 0px 16px 22px -8px #0c1d5933 !important;
    border: 1px solid #d9dff2 !important;
    padding: 10px !important;

    .title {
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    .order-body {
      width: 648px;
      text-align: left;
      margin-right: 48px;
      font-size: 16px;;
    }
  }
}

.patient-order {
  padding-left: 20px;

  .title {
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #525c7a;
    margin: 30px 0px;

    svg {
      margin-left: 2px;
      width: 21px;
      height: 21px;
      cursor: pointer;
    }
  }

  .order-body {
    height: 124px;
    border: 1px solid #d9dff2;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 48px;

    p {
      padding-left: 20px;
      padding-top: 20px;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #0c1d59;
    }

    .order-options {
      max-width: 396px;
      margin-left: 20px;
    }
  }
}

button {
  padding: 0;
  border: none;
  background-color: transparent;

  &.interval-add {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 8px;
    color: #305bf2;
  }
}

.title {
  font-family: 'Red Hat Display';
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-blue);
  margin-bottom: 27px;
}

.with-border {
  margin-bottom: 8px;
}

.schedules-content {
  padding: 20px;
  width: 100% !important;

  .title {
    font-family: 'Nunito Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #525c7a;
    margin-bottom: 16px;

    svg {
      margin-left: 2px;
      width: 21px;
      height: 21px;
      cursor: pointer;
    }
  }

  .times {
    border: 1px solid #d9dff2;
    border-radius: 8px;
    margin-bottom: 40px;

    .date-inputs {
      align-items: center;
      color: #8696ac;
      margin-bottom: 10px;

      .mx-datepicker {
        width: 100%;
        border: 1px solid var(--neutral-300) !important;
        border-radius: 8px !important;

        input {
          border-radius: 8px !important;
          text-align: center;
        }
      }
    }

    .times-data-inputs {
      display: grid;
      grid-template-columns: 180px 5px 180px 40px;
      grid-gap: 4px;
      align-items: center;
      color: #8696ac;
      margin-bottom: 10px;

      &.exception {
        display: flex !important;
        margin: auto;
        justify-content: center;

        input {
          text-align: center;
        }
      }

      .data-inputs {
        display: flex;

        .disabled {
          color: #8696ac;
          border: 1px solid #c6ceeb;
        }

        span {
          font-family: Nunito Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #0c1d59;
        }

        input {
          margin-top: 5px;
        }
      }

      .form-control {
        height: 38px;
      }

      .exception-times {
        max-width: 100px;
      }

      button {
        margin-left: 20px;
        svg {
          width: 20px;
          stroke: #7f91d2;
        }
      }
    }

    .operation-status {
      height: 25px;
      margin-top: 4px;
      width: 0 !important;
    }

    .statusBtn {
      height: 32px;
      margin-bottom: 10px;
    }

    .times-default {      

      .times-list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 24px;
        border-bottom: solid 1px #d9dff2;

        .col {
          max-width: 100px;
        }
        .times-tag {
          padding: 8px 16px;
          border-radius: 50px;
          background-color: #d9dff2;
          color: #000;
          width: fit-content;

          &.active {
            background: #ffebcc;
          }
        }
      }
    }

    .times-exceptions {
      position: relative;

      .times-modal {
        position: absolute;
        top: 0;
        left: -5px;
        width: calc(100% + 10px);
        background-color: #fff;
        box-shadow: 0px 4px 28px rgba(12, 29, 89, 0.1),
          0px 24px 48px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;

        .times-modal-content {
          padding: 24px;

          &.colored {
            padding: 16px 24px;
            background-color: #f4f5fb;
            border-top: solid 1px #d9dff2;
            border-bottom: solid 1px #d9dff2;
          }

          .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 1.5;
            margin-bottom: 16px;
            color: #525c7a;
          }

          .subtitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 16px;
            color: #525c7a;
          }
        }

        .times-modal-footer {
          padding: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          button {
            background-color: #305bf2;
            height: 38px;
            min-width: 20%;
            border-radius: 8px;
            border: none;
            margin-left: 8px;
            font-weight: bold;
            font-size: 16px;
            color: #fff;

            transition: all ease 0.3s;

            &:nth-child(1) {
              border: solid 2px #305bf2;
              color: #305bf2;
              background-color: #fff;
              min-width: 20%;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .times-exceptions-header {
        padding: 24px;

        border-bottom: solid 1px #d9dff2;

        h5 {
          font-family: 'Red Hat Display';
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: -0.01em;
          margin-bottom: 16px;
          color: #525c7a;
        }

        p {
          font-family: 'Nunito Sans';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 16px;
        }

        .add-btn {
          background-color: #305bf2;
          width: 100%;
          height: 38px;
          border-radius: 8px;
          border: none;
          color: #fff;

          font-family: 'Nunito Sans';
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;

          cursor: pointer;
          transition: all ease 0.3s;

          &:hover {
            background-color: #6184ff;
          }
        }
      }

      .times-table {
        .times-header-title {
          display: grid;
          grid-template-columns: 60% 40%;

          background-color: #f4f5fb;
          padding: 12px 24px;

          font-size: 16px;
          font-weight: 600;

          border-bottom: 1px solid #d9dff2;
        }

        .times-table-list {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            display: grid;
            grid-template-columns: 60% 40%;
            align-items: center;

            padding: 24px;

            border-bottom: 1px solid #d9dff2;

            div {
              &.dates {
              }

              &.hours {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                button {
                  padding: 0;
                  background: none;
                  border: none;

                  color: #7f91d2;

                  svg {
                    width: 16px;
                    stroke: #7f91d2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .consultation-duration {
    max-width: 440px;
  }
}
</style>

<!--- View das configurações da agenda - Responsável pelas configurações da Agenda da clinica. -->

<template>
  <div class="schedule-data">
    <p class="title">Configurações da Agenda</p>
    <div class="content">
      <div class="tabs">
        <span
          v-can="'CaFunc1'"
          class="tab"
          :class="{ active: currentTab == 'funcionamento' }"
          @click="currentTab = 'funcionamento'"
          >Funcionamento</span
        >
        <span
          v-can="'CaPer1'"
          class="tab"
          :class="{ active: currentTab == 'periodos' }"
          @click="currentTab = 'periodos'"
          >Períodos</span
        >
        <span
          v-can="'CaProf1'"
          class="tab"
          :class="{ active: currentTab == 'profissionais' }"
          @click="currentTab = 'profissionais'"
          >Profissionais</span
        >
        <span
          v-can="'CaSalSet1'"
          class="tab"
          :class="{ active: currentTab == 'salas' }"
          @click="currentTab = 'salas'"
          >Salas e setores</span
        >
        <span
          v-can="'CaInd1'"
          class="tab"
          :class="{ active: currentTab == 'indisponibilidades' }"
          @click="currentTab = 'indisponibilidades'"
          >Indisponibilidades</span
        >
        <!-- TODO: add necessary permission -->
        <span
          class="tab"
          :class="{ active: currentTab == 'restricoes' }"
          @click="currentTab = 'restricoes'"
          >Restrições da agenda</span
        >
      </div>
      <div class="body-config">
        <div v-if="currentTab == 'funcionamento'">
          <OperationView />
        </div>
        <div v-if="currentTab == 'periodos'">
          <PeriodsView />
        </div>
        <div v-if="currentTab == 'profissionais'">
          <ProfessionalsView />
        </div>
        <div v-if="currentTab == 'salas'">
          <RoomsView />
        </div>
        <div v-if="currentTab == 'indisponibilidades'">
          <OutagesView />
        </div>
        <div v-if="currentTab == 'restricoes'">
          <RestrictionsView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ProfessionalsView from "../components/ProfessionalsView";
import RoomsView from "../components/RoomsView";
import OperationView from "../components/OperationView";
import PeriodsView from "../components/PeriodsView";
import OutagesView from "../components/OutagesView";
import RestrictionsView from "../components/RestrictionsView.vue";

export default {
  name: "AgendaSettings",
  components: {
    ProfessionalsView,
    OperationView,
    PeriodsView,
    OutagesView,
    RoomsView,
    RestrictionsView,
  },
  data() {
    return {
      currentTab: "funcionamento",
    };
  },
  watch: {},
  mounted() {
    this.getClinicDays();
  },
  methods: {
    ...mapActions("scheduleConfig", ["getClinicDays"]),
  },
};
</script>

<style lang="scss" scoped>
.schedule-data {
  padding: 30px 24px;
  text-align: left;

  .title {
    font-family: "Red Hat Display";
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
    margin-bottom: 27px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    background-color: var(--neutral-000);
    border-radius: 8px;

    min-height: calc(100vh - 200px);

    .tabs {
      height: 72px;
      border-bottom: 1px solid var(--neutral-200);
      display: flex;
      align-items: center;

      .tab {
        font-weight: 600;
        font-size: 16px;
        color: var(--type-active);
        padding: 22px 24px;
        cursor: pointer;
        border-bottom: 3px solid var(--neutral-000);

        &.active {
          color: var(--orange);
          border-bottom: 4px solid var(--orange);
        }
      }
    }

    .body-config {
      width: 100%;
    }
  }
}

.vc-container {
  &.vc-is-expanded {
    border: none;

    --blue-600: #305bf2;
  }
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>

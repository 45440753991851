<template>
  <div>
    <div class="d-flex justify-content-end fakeTop">
      <b-button variant="primary" @click="openModal()"
        >Adicionar intervalo de idade</b-button
      >
    </div>

    <b-row>
      <b-col class="justify-content-center align-self-center">
        <h3>Intervalo de Idade</h3>
      </b-col>

      <b-col md="4" offset="5" align-self="center">
        <multi-select
          placeholder="Profissional"
          v-model="professionalFilterTable"
          :options="doctorsOptions"
          :searchable="true"
          @remove="handleRemotion($event, 'professionalFilterTable')"
          @select="handleInput($event, 'professionalFilterTable')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table-simple class="table">
          <thead>
            <tr>
              <td class="td-head">Médico</td>
              <td class="td-head">Intervalo de idade</td>
              <td class="td-head">Tomada de Ação</td>
              <td class="td-head"></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in data" :key="entry.key">
              <td>{{ entry.doctor }}</td>
              <td>{{ entry.ageRange }}</td>
              <td>{{ entry.action }}</td>

              <td>
                <div class="actions">
                  <Icon tooltip="Editar restrição">
                    <Pencil
                      class="pointer"
                      @click="
                        editContraint(
                          entry.id,
                          entry.rawDoctor,
                          entry.rawContrainstConfig,
                          entry.rawConstraintAction
                        )
                      "
                    />
                  </Icon>

                  <Icon tooltip="Remover restrição">
                    <Delete class="pointer" @click="deleteConstraint(entry.id)" />
                  </Icon>
                </div>
              </td>
            </tr>
          </tbody>
        </b-table-simple>
      </b-col>
    </b-row>

    <div class="centered" v-if="data.length === 0">
      <img
        src="@/assets/images/schedule-constraints-no-data.png"
        alt="Nenhuma restrição cadastrada"
        class="centered"
      />

      <p>Não há nenhuma regra cadastrada.</p>

      <p>Clique no "+” para adicionar uma nova regra de idade.</p>
    </div>

    <b-modal v-model="modalShow" size="lg" centered content-class="modalRule">
      <template #modal-header="{ close }">
        <p>Novo intervalo de idades</p>

        <Icon class="closeIcon">
          <Close class="pointer" @click="close()" />
        </Icon>
      </template>

      <template #default>
        <b-row>
          <b-col>
            <p>Médico</p>

            <multi-select
              placeholder="Profissional"
              v-model="professionalOnRuleAdd"
              :options="doctorsOptions"
              :searchable="true"
              @remove="handleRemotion($event, 'professionalAddRule')"
              @select="handleInput($event, 'professionalAddRule')"
            />

            <div v-if="validationError.professional" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-col>
        </b-row>

        <div class="ageRange">
          <b-row align-h="center">
            <b-col>
              <b-row>
                <b-col md="6">
                  <p>Intervalo de idade</p>
                  <small>Insira as faixas de idade que serão atendidas</small>
                </b-col>

                <b-col md="4" offset-md="2">
                  <b-btn variant="outline-primary" @click="addNewConstraint"
                    >+ Novo intervalo de idade</b-btn
                  >
                </b-col>
              </b-row>

              <div v-if="validationError.ageRange" class="custom-invalid-feedback">
                Campo obrigatório
              </div>
            </b-col>
          </b-row>

          <hr />

          <b-row align-h="center" v-for="index in insertCount" :key="index">
            <b-col md="5">
              <label>Idade mínima</label>
              <b-form-input
                type="text"
                v-model="tempConstraintConfig[index].min"
                placeholder="Ex: 7 anos"
                @blur="blurAge($event)"
              ></b-form-input>
            </b-col>

            <b-col md="5">
              <label>Idade máxima</label>
              <b-form-input
                type="text"
                v-model="tempConstraintConfig[index].max"
                placeholder="Ex: 7 anos"
                @blur="blurAge($event)"
              ></b-form-input>
            </b-col>

            <b-col md="2">
              <label>&nbsp;</label>
              <Icon>
                <Delete class="pointer" @click="removeTempConstraintConfig(index)" />
              </Icon>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col md="12">
            <span>
              Ação a ser tomada
              <Icon v-b-popover.hover="tooltip" class="iconTooltip">
                <Info class="pointer" />
              </Icon>
            </span>
          </b-col>

          <b-col md="12">
            <b-form-group>
              <b-form-radio-group
                v-model="dataToSave.constraint_action"
                :options="optionsActions"
                name="radio-options"
                class="radioText"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template #modal-footer>
        <b-button variant="primary" @click="saveRestriction()"
          >Salvar novo intervalo</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import Icon from "@/components/General/Icon";
import Delete from "@/assets/icons/new-delete.svg";
import Pencil from "@/assets/icons/pencil.svg";
import Close from "@/assets/icons/close.svg";
import Info from "@/assets/icons/info-icon.svg";

export default {
  components: {
    Icon,
    Delete,
    Pencil,
    Close,
    Info,
    MultiSelect: () => import("@/modules/schedule/components/MultiSelect"),
  },
  name: "RestrictionsAgeView",
  created() {
    // get data will run after the doctor filter change
    // this.getData();
    this.getDoctors();
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem("clinic")),

      professionalFilterTable: null,
      professionalOnRuleAdd: null,
      doctorsOptions: [],
      modalShow: false,
      optionsActions: [
        { value: "warning", text: "Apenas avisar" },
        { value: "block", text: "Bloqueio agendamento" },
      ],

      insertCount: 0,

      tempConstraintConfig: [
        {
          min: null,
          max: null,
        },
      ],

      dataToSave: {
        id: null,
        clinic_id: null,
        constraint_action: "warning",
        constraint_config: [],
        doctors: [],
      },

      data: [],

      validationError: {
        professional: false,
        ageRange: false,
      },
    };
  },
  props: {},
  computed: {
    tooltip() {
      return {
        html: true,
        placement: "bottom",
        trigger: "hover",

        content: () => {
          return "<strong>Diferença nas ações a serem tomadas:</strong><br><br><strong>Apenas avisar:</strong> Essa opção apenas avisa na sua agenda que a idade não se enquandra no perfil de pacientes determinado, mas permite o agendamento.<br><br><strong>Bloquear agendamento:</strong> Essa opção não permite o agendamento de qualquer paciente que não se enquadre no perfil de paciente determinado pela clínica ou pelo médico.";
        },

        customClass: "tooltip-custom",
      };
    },
  },
  methods: {
    blurAge(e) {
      const number = e.target.value.replace(/[^0-9]/g, '')

      if (parseInt(number) === 1) {
        e.target.value = number + ' ano'
      } else {
        e.target.value = number + ' anos'
      }
    },
    async editContraint(id, professional, constraintConfig, action) {
      this.dataToSave.id = id;
      this.professionalOnRuleAdd = professional;

      this.tempConstraintConfig = [
        {
          min: null,
          max: null,
        },
      ];

      constraintConfig.forEach((item) => {
        this.tempConstraintConfig.push({
          min: item.min,
          max: item.max,
        });
      });

      this.insertCount = constraintConfig.length;

      this.dataToSave.constraint_action = action;

      this.modalShow = true;
    },
    async deleteConstraint(id) {
      await this.api.deleteConstraint(id).then((response) => {
        this.getData();
      });
    },
    async getData(doctors = null) {
      const data = await this.api.indexScheduleConstraintsFiltered(
        this.clinic.id,
        encodeURIComponent(doctors)
      );

      const temp = [];

      data.data.forEach((entry) => {
        const doctor = entry.doctor;
        const constraints = entry.constraint_config;

        if (!entry.health_plan_id) {
          temp.push({
            id: entry.id,

            rawDoctor: [
              {
                label: doctor.name,
                value: doctor.id,
              },
            ],
            rawContrainstConfig: constraints,
            rawConstraintAction: entry.constraint_action,

            doctor: doctor.name,
            ageRange: constraints
              .map((c) => `${c.min} anos até ${c.max} anos`)
              .join(" e "),
            action:
              entry.constraint_action === "warning"
                ? "Aviso na agenda"
                : "Bloqueio da agenda",
          });
        }
      });

      this.data = temp;
    },
    close() {
      this.modalShow = false;
    },
    addNewConstraint() {
      this.tempConstraintConfig.push({
        min: null,
        max: null,
      });

      this.insertCount++;
    },
    saveRestriction() {
      this.validationError.professional = false;
      this.validationError.ageRange = false;
      let hasErrors = false;

      if (!this.dataToSave.doctors.length) {
        this.validationError.professional = true;
        hasErrors = true;
      }

      if (
        !this.dataToSave.constraint_config.length ||
        this.dataToSave.constraint_config.some((item) => item.min === null || item.max === null)
      ) {
        this.validationError.ageRange = true;
        hasErrors = true;
      }

      if (hasErrors) {
        return;
      }

      this.dataToSave.clinic_id = this.clinic.id;

      this.api
        .saveScheduleConstraint(this.dataToSave)
        .then((response) => {
          this.$toast.success("Restrição salva com sucesso", {
            title: "Sucesso",
            variant: "success",
            solid: true,
          });

          this.getData(null);

          this.modalShow = false;
        })
        .catch((error) => {
          console.log(error);

          this.$toast.error("Erro ao salvar restrição", {
            title: "Erro",
            variant: "danger",
            solid: true,
          });
        });
    },
    handleRemotion(value, source) {
      if (source === "professionalFilterTable") {
        if (value.value === "all") {
          this.professionalFilterTable = [];
        }

        if (this.professionalFilterTable.length < this.doctorsOptions.length) {
          this.professionalFilterTable = this.professionalFilterTable.filter(
            (option) => option.value !== "all"
          );
        }
      } else if (source === "professionalAddRule") {
        if (value.value === "all") {
          this.professionalOnRuleAdd = [];
        }

        if (this.professionalOnRuleAdd.length < this.doctorsOptions.length) {
          this.professionalOnRuleAdd = this.professionalOnRuleAdd.filter(
            (option) => option.value !== "all"
          );
        }
      }
    },
    handleInput(value, source) {
      if (source === "professionalFilterTable") {
        if (
          value.value === "all" ||
          this.professionalFilterTable.length === this.doctorsOptions.length - 1
        ) {
          this.professionalFilterTable = this.doctorsOptions;
        }
      } else if (source === "professionalAddRule") {
        if (
          value.value === "all" ||
          this.professionalOnRuleAdd.length === this.doctorsOptions.length - 1
        ) {
          this.professionalOnRuleAdd = this.doctorsOptions;
        }
      }
    },
    async getDoctors() {
      const options = [];

      await this.api.getDoctors(this.clinic.id).then((response) => {
        if (response.data.doctors.length > 0) {
          options.push({
            label: "Todos os médicos",
            value: "all",
          });
        }

        for (let i = 0; i < response.data.doctors.length; i++) {
          options.push({
            label: response.data.doctors[i].name,
            value: response.data.doctors[i].id,
          });
        }
      });

      this.doctorsOptions = options;

      this.professionalOnRuleAdd = [];
      this.professionalFilterTable = this.doctorsOptions;
    },
    openModal() {
      this.modalShow = true;

      this.insertCount = 0;

      this.dataToSave.id = null;

      this.tempConstraintConfig = [
        {
          min: null,
          max: null,
        },
      ];

      this.dataToSave.constraint_action = "warning";

      this.professionalOnRuleAdd = [];
    },
    removeTempConstraintConfig(index) {
      this.tempConstraintConfig.splice(index, 1);

      this.insertCount--;
    },
  },
  watch: {
    professionalOnRuleAdd(value) {
      this.dataToSave.doctors = value.map((item) => {
        if (item.value !== "all") {
          return item.value;
        }
      });
    },
    tempConstraintConfig: {
      handler: function (value) {
              this.dataToSave.constraint_config = value.filter(
                (item) => item.min !== null || item.max !== null
              ).map((item) => {
                return {
                  min: item.min !== null ? parseInt(item.min) : null,
                  max: item.max !== null ? parseInt(item.max) : null,
                };
              });
            },
      deep: true,
    },
    professionalFilterTable: {
      handler: function (value) {
        this.getData(value.map((item) => item.value));
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.tooltip-custom {
  background-color: #304388;
  font-family: Nunito Sans !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  .arrow::after,
  .arrow::before {
    border-top-color: #304388 !important;
    border-bottom-color: #304388 !important;
  }

  .popover-body {
    color: white !important;
  }

  strong {
    font-weight: 700;
  }
}
</style>

<style lang="scss" scoped>
.fakeTop {
  margin-top: -53px;
  margin-bottom: 30px;
}

.iconTooltip {
  display: inline-block;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

h3 {
  color: #0c1d59;
  font-size: 18px;
  font-weight: 700;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .pointer {
    cursor: pointer;
  }
}

.table {
  margin: 20px 0;
  thead {
    td {
      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }
  tr,
  td {
    padding: 10px 6px;
    vertical-align: middle;
    color: var(--type-active);
    font-size: 14px;
    line-height: 20px;
  }
  .td-head {
    color: var(--dark-blue);
    font-size: 16px;
    line-height: 24px;
    border: none;
    background-color: var(--neutral-100);
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .pointer {
      cursor: pointer;
    }
  }
}

.closeIcon svg {
  width: 24px;
  height: 24px;
}

.ageRange {
  background: #f8f8fd;
  padding: 16px 16px 16px 16px;

  small {
    color: #000000de;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .btn {
    font-family: Nunito Sans !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
  }

  label {
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #142666;
  }
}
</style>

<style>
.modalRule {
  border: 1px solid #c6ceeb !important;
  box-shadow: 0px 4px 4px 0px #0c1d591a;
  border-radius: 16px;
  border: 1px !important;
  padding: 12px 0px 0px 0px;
}

.modalRule .modal-header {
  font-family: "Nunito Sans";
  color: #0c1d59;
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;

  padding: 0px 24px 12px 24px;
}

.modalRule .modal-body {
  font-family: "Nunito Sans";
  color: #0c1d59;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 24px 0px 24px;
}

.modalRule .btn-outline-primary {
  font-family: Nunito Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: var(--blue-500);
}

.radioText label {
  color: #525c7a !important;
  font-family: Nunito Sans;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
</style>

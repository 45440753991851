<template>
    <div class="periods-body">
        <span class="title">
            Defina os horários de cada período na sua agenda
            <Info id="periods-tooltip"/>
        </span>

        <div class="periods-container">
            <div class="item" v-for="(periods, idx) in periodsOptions"
            :key="periods.id"
            :class="{'last': idx === periodsOptions.length-1}">
                <div class="info">
                    <div class="header">
                        <p>Nome do período</p>
                        <p>Cor</p>
                    </div>
                    <div class="info-body">
                        <input class="name"
                        maxlength="16"
                        v-model="periodsForm[periods.value].name"
                        @change="savePeriod(periods.value)"
                        :disabled="!canEdit"
                        />

                        <multiselect
                        v-model="periodsForm[periods.value].color"
                        :options="colors"
                        label="value"
                        track-by="name"
                        :showLabels="false"
                        :searchable="false"
                        :allowEmpty="false"
                        placeholder=""
                        @input="savePeriod(periods.value)"
                        class="with-border colors"
                        :disabled="!canEdit"
                        >
                        <template slot="caret">
                            <div class="chevron">
                            <ChevronDown />
                            </div>
                        </template>

                        <template slot="singleLabel" slot-scope="{ option }">
                            <div class="fc-badge d-flex"
                            :class="option.name">
                            </div>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="fc-badge d-flex"
                            :class="props.option.name">
                            </div>
                        </template>

                        <template slot="noOptions">
                            Nenhuma opção
                        </template>

                        <template slot="noResult">
                            Nenhum resultado
                        </template>

                        </multiselect>

                        <div class="times d-flex">
                            <input
                                v-model="periodsForm[periods.value].start"
                                v-mask="'##:##'"
                                @change="savePeriod(periods.value)"
                                :disabled="!canEdit"
                            />
                            -
                            <input
                                v-model="periodsForm[periods.value].end"
                                v-mask="'##:##'"
                                @change="savePeriod(periods.value)"
                                :disabled="!canEdit"
                            />
                            <!-- <Trash /> -->
                        </div>
                    </div>

                    <b-tooltip target="periods-tooltip" triggers="hover">
                        <p class="title">Atendimento de pacientes:</p>
                        <p class="order-body">
                            Configure os períodos para otimizar a organização da sua agenda definindo horários de início, fim e cores para cada período. Atenção: Caso os períodos sejam desativados e não estejam de acordo com os horários da sua agenda, não será possível visualizar esses perídos na agenda ou filtrá-los.
                        </p>
                    </b-tooltip>
                </div>
                <div class="status">
                    <span v-if="periodsForm[periods.value].status">
                        Ativo
                    </span>
                    <span v-else>
                        Inativo
                    </span>
                    <toggle-button
                    class="statusBtn"
                    :sync="true"
                    :height="32"
                    :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                    v-model="periodsForm[periods.value].status"
                    @change="savePeriod(periods.value)"
                    :disabled="!canEdit"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { userHasPermission } from '@/utils/localStorageManager'
import ChevronDown from '@/assets/icons/chevron-down.svg'
// import Trash from '@/assets/icons/trash.svg'
import Info from '@/assets/icons/info.svg'

export default {
  name: 'Periods',
  components: {
      ChevronDown,
    //   Trash,
      Info
  },
  data() {
    return {
      canEdit: userHasPermission('CaPer2'),
      clinic: [],
      periodsForm: [],

      periodsOptions: [],

      colors: [
          {value: 1, name: 'a', idx: 0},
          {value: 2, name: 'b', idx: 1},
          {value: 3, name: 'c', idx: 2},
          {value: 4, name: 'd', idx: 3},
          {value: 5, name: 'e', idx: 4},
          {value: 6, name: 'f', idx: 5},
          {value: 7, name: 'g', idx: 6},
          {value: 8, name: 'h', idx: 7},
          {value: 9, name: 'i', idx: 8},
          {value: 10, name: 'j', idx: 9},
          {value: 11, name: 'k', idx: 10},
          {value: 12, name: 'l', idx: 11},
      ],

      defaultColors: [
          {value: 1, name: 'a', idx: 0},
          {value: 2, name: 'b', idx: 1},
          {value: 3, name: 'c', idx: 2},
          {value: 4, name: 'd', idx: 3},
          {value: 5, name: 'e', idx: 4},
          {value: 6, name: 'f', idx: 5},
          {value: 7, name: 'g', idx: 6},
          {value: 8, name: 'h', idx: 7},
          {value: 9, name: 'i', idx: 8},
          {value: 10, name: 'j', idx: 9},
          {value: 11, name: 'k', idx: 10},
          {value: 12, name: 'l', idx: 11},
      ],

      tmpColors: {
          manha: null,
          tarde: null,
          noite: null,
          madrugada: null
      },
    }
  },
  mounted() {
    this.clinic = JSON.parse(localStorage.getItem('clinic'))

    this.checkPeriods()
  },
  methods: {
      checkPeriods(){

        this.api
            .checkPeriods(this.clinic.id)
            .then(res => {
                this.colors = [
                    {value: 1, name: 'a', idx: 0},
                    {value: 2, name: 'b', idx: 1},
                    {value: 3, name: 'c', idx: 2},
                    {value: 4, name: 'd', idx: 3},
                    {value: 5, name: 'e', idx: 4},
                    {value: 6, name: 'f', idx: 5},
                    {value: 7, name: 'g', idx: 6},
                    {value: 8, name: 'h', idx: 7},
                    {value: 9, name: 'i', idx: 8},
                    {value: 10, name: 'j', idx: 9},
                    {value: 11, name: 'k', idx: 10},
                    {value: 12, name: 'l', idx: 11},
                ]

                this.periodsOptions = res.data.map(el => {
                    return {
                        name: el.name,
                        value: el.name,
                        id: el.id
                    }
                })

                res.data.forEach(el => {
                    this.periodsForm[el.name] = {
                        id: el.id,
                        name: el.name,
                        color: {
                            value: el.color,
                            name: el.color_name,
                            idx: el.color_idx
                        },
                        status: el.status,
                        start: this.moment(el.start, 'HH:mm:ss').format('HH:mm'),
                        end: this.moment(el.end, 'HH:mm:ss').format('HH:mm')
                    }

                    const index = this.colors.map(i => i.name).indexOf(el.color_name);

                    this.colors.splice(index, 1)
                });
            })
            .catch(err => console.log('err', err))
            .finally(() => {
            })
      },
      savePeriod(value){
        if(this.periodsForm[value].end === '00:00'){
            this.periodsForm[value].end = '23:59'
        }

        this.api
            .savePeriods(this.clinic.id, this.periodsForm[value])
            .then(res => {
                this.checkPeriods()
            })
            .catch(err => console.log('err', err))
      },
    //   teste(event, type){

    //     var checkPeriods = true



    //     for(var period in this.periodsForm) {

    //         console.log(period)

    //         // if(period.color.value === event.value){
    //         //     checkPeriods = false
    //         // }
    //     }

    //     if(checkPeriods){
    //         this.periodsForm[type.value].color = event
    //         console.log(this.periodsForm[type.value])
    //         console.log(event)
    //     }
    //     // // const colorIdx = this.colors.findIndex(x => x.name === event.name);
    //     // const color = this.colors.find(x => x.name === event.name);
    //     // const tempColor = this.tmpColors[type.value]
    //     // // const formColor = this.periodsForm[type.value].color

    //     // console.log(color)

    //     // if(tempColor === null){
    //     //     this.tmpColors[type.value] = this.colors.find(x => x.name === event.name)
    //     //     this.colors.splice(color.idx, 1)
    //     // }
    //     // else{
    //     //     this.colors.splice(tempColor.idx, 0, tempColor);
    //     //     this.colors.join()
    //     //     this.colors.splice(color.idx, 1)
    //     //     this.tmpColors[type.value] = color
    //     // }
    //   },
      checkColorsDefault(){

          if(this.periodsForm.manha.color === ''){
              this.periodsForm.manha.color = {value: 6, name: 'f', idx: 5}
          }

          if(this.periodsForm.tarde.color === ''){
              this.periodsForm.tarde.color = {value: 2, name: 'b', idx: 1}
          }

          if(this.periodsForm.noite.color === ''){
              this.periodsForm.noite.color = {value: 7, name: 'g', idx: 6}
          }

          if(this.periodsForm.madrugada.color === ''){
              this.periodsForm.madrugada.color = {value: 8, name: 'h', idx: 7}
          }
      }
  }
}

</script>
<style lang="scss">
.periods-body {
    margin: 50px 0px;
    padding: 0px 50px;
    .title {
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #525C7A;

        svg {
            width: 20px;
            height: 20px;
            margin-left: 3px;
            cursor: pointer;
        }
    }

    .periods-container {
        margin-top: 20px;
        border: 1px solid #D9DFF2;
        box-sizing: border-box;
        border-radius: 8px;

        input {
            height: 38px;

            &:focus {
                outline: none !important;
                border-color: inherit;
                border: 1px solid var(--blue-600) !important;
            }
        }

        .item {
            padding: 20px;
            display: grid;
            grid-template-columns: 60% 40%;
            border-bottom: 1px solid #D9DFF2;

            &.last {
                padding: 20px 20px 40px 20px;
            }

            .info {
                width: 100%;

                .header {
                    display: grid;
                    grid-template-columns: 40% 20% 40%;
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 150%;
                    color: #0C1D59;;
                }

                .info-body{
                    display: grid;
                    grid-template-columns: 40% 20% 40%;
                    align-items: center;

                    .name {
                        padding: 13px;
                    }

                    input {
                        width: 95%;
                        border: 1px solid #C6CEEB;
                        box-sizing: border-box;
                        border-radius: 8px;

                        font-family: Nunito Sans;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #525C7A;
                    }

                    .colors {
                        height: 38px;
                        max-width: 100px;
                        margin: 0px;

                        .multiselect__single{
                            display: flex;
                            align-items: center;
                        }

                        .fc-badge {
                        width: 28px;
                        height: 28px;
                        border-radius: 100px;
                        border: 1px solid #525c7a;
                        margin-right: 9px;

                        &.a {
                            border-color: #FFEA9E;
                            background-color: #FFEA9E;
                        }

                        &.b {
                            border-color: #FFCC18;
                            background-color: #FFCC18;
                        }

                        &.c {
                            border-color: #FF9900;
                            background-color: #FF9900;
                        }

                        &.d {
                            border-color: #FF6B00;
                            background-color: #FF6B00;
                        }
                        &.e {
                            border-color: #C2E9FF;
                            background-color: #C2E9FF;
                        }
                        &.f {
                            border-color: #00C2FF;
                            background-color: #00C2FF;
                        }
                        &.g {
                            border-color: #3D86DD;
                            background-color: #3D86DD;
                        }
                        &.h {
                            border-color: #396D84;
                            background-color: #396D84;
                        }
                        &.i {
                            border-color: #B6FFCA;
                            background-color: #B6FFCA;
                        }
                        &.j {
                            border-color: #00C773;
                            background-color: #00C773;
                        }
                        &.k {
                            border-color: #069763;
                            background-color: #069763;
                        }
                        &.l {
                            border-color: #097C53;
                            background-color: #097C53;
                        }
                        }

                        .multiselect__tags {
                            height: 38px;
                        }

                        .multiselect__content-wrapper {
                            width: 320px;
                            border-radius: 8px;
                            box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 16px 22px -8px rgba(12, 29, 89, 0.2);
                            padding: 10px !important;
                            margin: 0px !important;

                            ul {
                                height: 100%;
                                width: 100%;
                                display: inline-grid !important;
                                grid-template-columns: auto auto auto auto;
                                grid-gap: 15px;
                                align-items: center;
                                justify-content: center;

                            li {
                                width: 60px;
                                height: 38px;
                                border: 1px solid #C6CEEB;
                                box-sizing: border-box;
                                border-radius: 8px;

                                .multiselect__option--highlight {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 8px;
                                }

                                .multiselect__option--selected {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 8px;
                                }
                            }
                            }
                        }
                    }

                    .times {
                        justify-content: center;
                        align-items: center;

                        input {
                            max-width: 80px;
                            text-align: center;
                        }

                        svg {
                            width: 25px;
                            height: 25px;
                            margin-left: 13px;
                            stroke: #7F91D2;
                            cursor: pointer;
                        }
                    }
                }
            }

            .status {
                width: 100%;
                align-items: center;
                display: flex;
                justify-content: flex-end;

                .statusBtn {
                    margin-left: 13px;
                }
            }
        }
    }
}
</style>

<template>
  <div class="restriction">
    <b-container fluid>
      <Tab
        ref="restriction_tabs"
        :index="currentTabIndex"
        v-bind:keys="tabs"
        v-on:onChange="onChangeTab"
        id="restriction-tabs"
        class="restriction__tabs"
      >
        <RestrictionsAge v-if="tabs[currentTabIndex] === 'Idade'" class="tab" />

        <RestrictionsHealthPlan v-if="tabs[currentTabIndex] === 'Convênio'" class="tab" />
      </Tab>
    </b-container>
  </div>
</template>

<script>
import RestrictionsAge from "./RestrictionsAgeView.vue";
import RestrictionsHealthPlan from "./RestrictionsHealthPlanView.vue";

export default {
  name: "RestrictionsScheduleView",
  data() {
    return {
      currentTabIndex: 0,
      tabs: ["Idade", "Convênio"],
    };
  },
  components: {
    Tab: () => import("@/components/TabPills"),
    RestrictionsAge,
    RestrictionsHealthPlan,
  },
  methods: {
    onChangeTab(index) {
      this.currentTabIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.restriction {
  padding: 40px 24px 40px 24px;
}
</style>

<style lang="scss">
.tab-container .tab-container-keys {
  border-bottom: unset !important;
}

.tab-container ul li a.item {
  background: #f4f5fb;
  color: #525c7a;
  padding: 8px 16px 8px 16px;
}

.tab-container ul li a.item.active {
  background: #305bf2 !important;
  color: #ffffff !important;
}
</style>

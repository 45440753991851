<template>
  <b-modal
      id="update-duration-modal"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="header">
        <div class="header-content">
            <p>Duração padrão da consulta</p>
            <v-close class="close-svg" @click="updateDuration(null)" />
        </div>
      </div>
      <div class="body">
        <v-info />
        <div>
          <p>
            Deseja mudar a duração de
              <span>{{ getDurationLabel(oldValue) }}</span> para
              <span>{{ getDurationLabel(newValue) }}</span> em quais intervalos?
          </p>
          <label for="only-default">
            <input
              type="radio"
              id="only-default"
              value="only-default"
              name="duration_radio"
              v-model="durationRadio">
            <span>Somente o intervalo padrão</span>
          </label>
          <br>
          <label for="only-equals">
            <input
              type="radio"
              id="only-equals"
              value="only-equals"
              name="duration_radio"
              v-model="durationRadio">
            <span>Intervalos que tem a duração de ({{ getDurationLabel(oldValue) }})</span>
          </label>
          <br>
          <label for="all-intervals">
            <input
              type="radio"
              id="all-intervals"
              value="all-intervals"
              name="duration_radio"
              v-model="durationRadio">
            <span>Todos os intervalos</span>
          </label>
        </div>
      </div>

      <div class="actions">
        <b-button
          class="cancel-button"
          variant="outline-primary"
          @click="updateDuration(null)"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="updateDuration(durationRadio)"
        >
          Confirmar
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import Info from '@/assets/icons/toastification-info.svg'

export default {
  name: 'ConfirmProcedureDeletion',
  components: {
    'v-close': Close,
    'v-info': Info,
  },
  props: {
    oldValue: Number,
    newValue: Number,
    updateDuration: Function,
    getDurationLabel: Function,
  },
  data() {
    return {
      durationRadio: 'only-default'
    }
  },
}
</script>

<style lang="scss">
  #update-duration-modal {
    .modal-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 675px;
      text-align: center;

      .modal-body {
        padding: 0;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);

        .header {
          border-bottom: 1px solid var(--neutral-200);

          .header-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin: 24px;

              .close-svg {
                  height: 24px;
                  width: 24px;
                  fill: var(--neutral-500);
              }
          }
        }
        .body {
          padding: 60px;
          padding-bottom: 0;
          color: var(--type-active);
          font-size: 18px;
          font-weight: 600;

          svg {
            aspect-ratio: 1;
            width: 96px;
          }

          > div {

            text-align: left;

            label {
              font-size: 16px;
              white-space: nowrap;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              span {
                margin-left: 10px;
                color: var(--type-active);
                white-space: normal;
              }
            }
          }

          p {
            margin-top: 40px;
            margin-bottom: 25px;
          }

          span {
            color: var(--blue-500);
          }
        }

        .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 60px;
          padding-top: 26px;

          button {
            margin-left: 10px !important;
            font-size: 16px;
          }

          .cancel-button {
            font-weight: 700 !important;
            color: var(--blue-500) !important;
          }
        }
      }
    }
  }
</style>
